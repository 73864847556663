const customTheme = {
    tokens: {
        fontFamilyBase: "'Ubuntu', sans-serif",
        borderRadiusButton: "4px",
        colorBackgroundItemSelected: '#9fcfca',
        colorBackgroundLayoutMain: '#fff',
        colorBackgroundLayoutToggleActive: '#647775',
        colorTextBodySecondary: '#647775',
        colorTextAccent: '#9fcfca',
        colorTextHeadingDefault: '#647775',
    },
    contexts: {
        'top-navigation': {
            tokens: {
                colorTextBodyDefault: "#000",
                colorTextTopNavigationTitle: '#fff',
                colorBackgroundContainerHeader: '#9fcfca',
                colorTextHomeHeaderDefault: '#9fcfca',
                colorTextHomeHeaderSecondary: '#9fcfca',
                colorBackgroundContainerContent: '#9fcfca',
                colorTextDropdownItemFilterMatch: '#e80808'
            },
        },
        'header': {
            tokens: {
                colorBackgroundLayoutMain: '#f1f8f7',
                colorTextBreadcrumbCurrent: '#000000',
                colorTextLinkDefault: '#000',
                colorTextHeadingDefault: '#000',
                colorTextGroupLabel: '#000'
            },
        },
    }
};

export default customTheme;
