import React, { useState, useEffect} from 'react';
import fetcher from "../../../utils/fecther";
import useSWR, {mutate} from 'swr';
import {
    ColumnLayout,
    Container,
    Header,
    ContentLayout,
    SpaceBetween,
    Textarea,
    Form,
    Input,
    Button,
    FormField, Spinner, Select, Toggle
} from '@cloudscape-design/components';
import {useParams, useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from "uuid";
import {useFlash} from "../../../utils/hooks/useFlash";
import useChangeDetector from "../../../utils/useChangeDetector";
import {formConfig} from "../config";

type FormData = Record<string, string | number | boolean | null>;
type FieldErrors = Record<string, string | null>;

export default function RateLimitingEdit() {
    // state to control changes made
    const {
        item,
        setItem,
        setItemShadow,
        changesDetected
    } = useChangeDetector<any>({});

    // state
    const [formSubmited, setFormSubmited] = useState<boolean>(false);
    const [textareaCharCount, setTextareaCharCount] = useState<number>(0);
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

    // Hooks
    const {featureId} = useParams();
    const navigate = useNavigate();
    const {addFlash, removeFlash} = useFlash();

    const {data, isLoading} = useSWR(`/rate-limit/${featureId}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });

    // Saves the results form the API into local state
    useEffect(() => {
        if (data?.data?.data) {
            setItem(data.data.data);
            setItemShadow(data.data.data);
        }
    }, [data, setItem, setItemShadow]);

    const handleInputChange = (fieldName: string, value: string | number | boolean, fieldType?: string) => {
        let processedValue: string | number | boolean = value;

        // If the field's input type is 'number', try converting the string value to a number
        if (fieldType === "number") {
            const numericValue = parseFloat(value as string);
            if (!isNaN(numericValue)) {
                processedValue = numericValue;
            }
        }

        const newFormData = {...item, [fieldName]: processedValue};
        setItem(newFormData);

        if (fieldName === "prompt" && typeof processedValue === "string") {
            setTextareaCharCount(processedValue.length);
        }

        validateForm(newFormData);
    };



    const validateForm = (dataToValidate: FormData) => {
        const errors: FieldErrors = {};

        formConfig.forEach(field => {
            if (field.validation) {
                const error = field.validation(dataToValidate[field.name]);
                if (error) {
                    errors[field.name] = error;
                }
            }
        });

        setFieldErrors(errors);
        return !Object.keys(errors).length;
    };

    // Handle form submission
    async function handleSubmitForm() {
        setFormSubmited(true);

        try {
            const payload = {
                ...item,
                promptName: featureId
            };

            await fetcher(`/rate-limit/${featureId}`, 'PUT', payload);
            const messageId = uuidv4();
            addFlash({
                type: "success",
                content: `Successfully updated ${featureId}`,
                dismissible: true,
                dismissLabel: "Dismiss",
                onDismiss: () => removeFlash(messageId),
                id: messageId
            });
            navigate('/rate-limiting')
            await mutate('/rate-limit');
        } catch (error) {
            const messageId = uuidv4();
            addFlash({
                type: "error",
                content: `Error updating ${featureId}`,
                dismissible: true,
                dismissLabel: "Dismiss",
                onDismiss: () => removeFlash(messageId),
                id: messageId
            });
        } finally {
            setFormSubmited(false);
        }
    }

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                >
                    {isLoading ? <Spinner/> : data?.data?.version}
                </Header>
            }
        >
            <Container>
                <form onSubmit={e => {
                    e.preventDefault();
                    handleSubmitForm();
                }}>
                    <Form
                        variant="embedded"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link"
                                        onClick={() => navigate('/rate-limiting')}>
                                    Cancel
                                </Button>
                                <Button variant="primary"
                                        loading={formSubmited}
                                        disabled={!changesDetected}>Submit</Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction="vertical" size="xl">
                            {/* Render fields with fullWidth true */}
                            {formConfig.filter(field => field.fullWidth && field.editType !== "HIDDEN").map(field => (
                                <ColumnLayout columns={1} key={field.name} >
                                    <FormField label={field.label} errorText={fieldErrors[field.name]} constraintText={field.inputType === "textarea" ?
                                        `Characters remaining: ${1000 - textareaCharCount}` :
                                        field.constraintText}  >
                                        {field.inputType === "text" && (
                                            <Input
                                                onChange={({detail}) => handleInputChange(field.name, detail.value)}
                                                value={String(item[field.name])}
                                                inputMode="text"
                                                placeholder={field.placeholder}
                                                disabled={field.editType === "READONLY"}
                                            />
                                        )}
                                        {field.inputType === "textarea" && (
                                            <Textarea
                                                onChange={({ detail }) => handleInputChange(field.name, detail.value)}
                                                value={String(item[field.name]) || ""}
                                                placeholder={field.placeholder}
                                                disabled={field.editType === "READONLY"}
                                                rows={15}
                                            />
                                        )}
                                    </FormField>
                                </ColumnLayout>
                            ))}

                            {/* Render regular fields */}
                            <ColumnLayout columns={3}>
                                {formConfig.filter(field => !field.fullWidth).map(field => (
                                    <FormField label={field.label} errorText={fieldErrors[field.name]} constraintText={field.constraintText} key={field.name}>
                                        {(field.inputType === "text" || field.inputType === "number") && (
                                            <Input
                                                onChange={({detail}) => handleInputChange(field.name, detail.value, field.inputType)}
                                                value={String(item[field.name])}
                                                inputMode={field.inputType === "number" ? "numeric" : "text"}
                                                placeholder={field.placeholder}
                                                disabled={field.editType === "READONLY"}
                                            />
                                        )}
                                        {field.inputType === "dropdown" && (
                                            <Select
                                                selectedOption={{ label: String(item[field.name]), value: String(item[field.name]) }}
                                                onChange={({ detail }) => {
                                                    if (detail.selectedOption.value) {
                                                        handleInputChange(field.name, detail.selectedOption.value)
                                                    }
                                                }}
                                                options={field.options ? field.options.map(option => ({
                                                    label: option.label,
                                                    value: option.id
                                                })) : []}
                                                disabled={field.editType === "READONLY"}
                                            />
                                        )}
                                        {field.inputType === "toggle" && (
                                            <Toggle
                                                onChange={({ detail }) =>
                                                    handleInputChange(field.name, detail.checked)
                                                }
                                                checked={Boolean(item[field.name])}
                                                disabled={field.editType === "READONLY"}
                                            />
                                        )}
                                    </FormField>
                                ))}
                            </ColumnLayout>
                        </SpaceBetween>
                    </Form>
                </form>
            </Container>
        </ContentLayout>
    );
}

