import React from 'react';
import {Link} from "react-router-dom";
import {
    CollectionPreferences, StatusIndicator,
} from '@cloudscape-design/components';


const rawColumns = [
    {
        id: "version",
        header: "Name",
        cell: item => <Link to={`/prompt-engineering/edit/${item.version}`}>{item.version}</Link> || "-",
        sortingField: "version"
    },
    {
        id: "documentLimit",
        header: "documentLimit",
        cell: item => item.data.documentLimit || "-",
    },
    {
        id: "genAIModel",
        header: "genAIModel",
        cell: item => item.data.genAIModel || "-",
    },
    {
        id: "genAICompletionTokenLimit",
        header: "genAICompletionTokenLimit",
        cell: item => item.data.genAICompletionTokenLimit || "-",
    },
    {
        id: "dynamicPromptCompletionTokenLimit",
        header: "dynamicPromptCompletionTokenLimit",
        cell: item => item.data.dynamicPromptCompletionTokenLimit || "-",
    },
    {
        id: "embeddingSearchStatus",
        header: "embeddingSearchStatus",
        cell: item => item.data.embeddingSearchStatus ? "Active" : <StatusIndicator type="warning">Not active</StatusIndicator>,
    },
    {
        id: "summaryStatus",
        header: "summaryStatus",
        cell: item => item.data.summaryStatus ? "Active" : <StatusIndicator type="warning">Not active</StatusIndicator>,
    },
    {
        id: "dynamicPromptStatus",
        header: "dynamicPromptStatus",
        cell: item => item.data.dynamicPromptStatus ? "Active" : <StatusIndicator type="warning">Not active</StatusIndicator>,
    },
    {
        id: "tokenBudget",
        header: "tokenBudget",
        cell: item => item.data.tokenBudget || "-",
    },
    {
        id: "similarityThreshold",
        header: "similarityThreshold",
        cell: item => item.data.similarityThreshold || "-",
    },
    {
        id: "summaryModel",
        header: "summaryModel",
        cell: item => item.data.summaryModel || "-",
    },
    {
        id: "prompt",
        header: "prompt",
        cell: item => item.data.prompt || "-",
    },
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));


const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});

const CONTENT_DISPLAY_OPTIONS = [
    {id: 'version', label: 'version', alwaysVisible: true},
    {id: 'documentLimit', label: 'documentLimit', alwaysVisible: true},
    {id: 'genAIModel', label: 'genAIModel', alwaysVisible: true},
    {id: 'tokenBudget', label: 'tokenBudget', alwaysVisible: true},
    {id: 'similarityThreshold', label: 'similarityThreshold', alwaysVisible: true},
    {id: 'summaryModel', label: 'summaryModel', alwaysVisible: true},
    {id: 'embeddingModel', label: 'embeddingModel', alwaysVisible: true},
    {id: 'genAICompletionTokenLimit', label: 'genAICompletionTokenLimit', alwaysVisible: true},
    {id: 'dynamicPromptCompletionTokenLimit', label: 'embedynamicPromptCompletionTokenLimitddingModel', alwaysVisible: true},
    {id: 'summaryCompletionTokenLimit', label: 'summaryCompletionTokenLimit', alwaysVisible: true},
    {id: 'embeddingSearchStatus', label: 'embeddingSearchStatus', alwaysVisible: true},
    {id: 'summaryStatus', label: 'summaryStatus', alwaysVisible: true},
    {id: 'dynamicPromptStatus', label: 'dynamicPromptStatus', alwaysVisible: true},
    {id: 'prompt', label: 'prompt', alwaysVisible: true},
];

export const PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10 Prompts'},
    {value: 30, label: '30 Prompts'},
    {value: 50, label: '50 Prompts'},
];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
        {id: 'version', visible: true},
        {id: 'documentLimit', visible: true},
        {id: 'genAIModel', visible: true},
        {id: 'genAICompletionTokenLimit', visible: true},
        {id: 'dynamicPromptCompletionTokenLimit', visible: true},
        {id: 'summaryCompletionTokenLimit', visible: true},
        {id: 'embeddingSearchStatus', visible: true},
        {id: 'summaryStatus', visible: true},
        {id: 'dynamicPromptStatus', visible: true},
        {id: 'tokenBudget', visible: true},
        {id: 'similarityThreshold', visible: true},
        {id: 'summaryModel', visible: true},
        {id: 'embeddingModel', visible: true},
        {id: 'prompt', visible: true},
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: "comfortable",
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled = false,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
                            }) => (
    <CollectionPreferences
        title={"Preferences"}
        cancelLabel={"Cancel"}
        confirmLabel={"Confirm"}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({detail}) => setPreferences(detail)}
        pageSizePreference={{title: "Page size", options: pageSizeOptions}}
        wrapLinesPreference={{label: "Wrap lines", description: "Select to see all the text and wrap the lines"}}
        stripedRowsPreference={{label: "Striped rows", description: "Select to add alternating shaded rows"}}
        contentDensityPreference={{
            label: "Compact mode",
            description: "Select to display content in a denser, more compact mode"
        }}
        contentDisplayPreference={{title: "Select visible content / order", options: contentDisplayOptions}}
    />
);