import { Auth } from 'aws-amplify';

const BASE_URL = process.env.REACT_APP_API_URL;

async function getAuthToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error('Error getting the authentication token', error);
    return null;
  }
}

async function fetcher(
  endpoint: string | null,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET',
  data: Record<string, any> | null = null
) {
  if (!endpoint) return null;
  const token = await getAuthToken();
  if (!token) {
    throw new Error('Unable to fetch the authentication token');
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const fullUrl = `${BASE_URL}${endpoint}`;
  console.log(fullUrl);

  const requestConfig: RequestInit = {
    method,
    headers,
  };

  if (['POST', 'PUT', 'PATCH'].includes(method) && data) {
    requestConfig.body = JSON.stringify(data);
  }
  console.log('fetch', fullUrl, requestConfig);
  const response = await fetch(fullUrl, requestConfig);

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error);
  }

  if (method === 'DELETE') {
    return null;
  }

  // First, check for content and try to parse JSON only if there's data
  const text = await response.text();
  return text ? JSON.parse(text) : null;
}

export default fetcher;
