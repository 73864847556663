import React, { createContext, useContext, useState } from "react";

const PeriodContext = createContext();

export const usePeriod = () => {
  const context = useContext(PeriodContext);
  if (!context) {
    throw new Error("usePeriod must be used within a PeriodProvider");
  }
  return context;
};

export const PeriodProvider = ({ children }) => {
  const [period, setPeriod] = useState({
    "amount": 1,
    "unit": "week",
    "type": "relative"
  });
  const [audience, setAudience] = useState({ label: "All", value: "All" });

  return (
    <PeriodContext.Provider value={{ period, setPeriod, audience, setAudience }}>
      {children}
    </PeriodContext.Provider>
  );
};
