import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// @ts-ignore
import agent from '../../../../components/conversationBubbles/aiah.svg';
// @ts-ignore
import customer from '../../../../components/conversationBubbles/customer.jpeg';
import { Auth } from 'aws-amplify';
import { motion } from 'framer-motion';
// @ts-ignore
import { messageState, messageTypes } from '../contexts/chatWebSocketContext.tsx';
import './messageBubble.css';
import Input from '@cloudscape-design/components/input';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import fetcher from '../../../../utils/fecther';
import { Spinner } from '@cloudscape-design/components';

interface IAvatar {
  imageSrc: string;
  altText: string;
}

interface IUser {
  given_name?: string;
  family_name?: string;
}

export default function MessageBubble(props: {
  contentType: messageTypes;
  message: string;
  timeStamp: Date;
  state: messageState;
  messageId: string;
  className?: string;
}) {
  const { contentType, message, timeStamp, messageId, state } = props;
  const [user, setUser] = useState<IUser | undefined>(undefined);

  function convertToHtml(message: string) {
    // Convert newlines to <br> tags
    console.log(message);
    const transformed = message.replace(/\n/g, '<br>');

    return transformed;
  }

  async function checkUser() {
    try {
      const res = await Auth.currentAuthenticatedUser();
      setUser(res.attributes);
    } catch (error) {
      console.log('Error retrieving user:', error);
    }
  }

  useEffect(() => {
    checkUser();
  }, []);

  const [feedback, setFeedback] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<'SUCCESS' | 'LOADING' | 'NONE' | 'ERROR'>('NONE');

  async function handleThumbsClick(value: number) {
    setFeedbackSubmitted('LOADING');
    try {
      let payload = {
        rating: value,
        comment: feedback,
      };

      const response = await fetcher(`/feedback/${messageId}`, 'POST', payload);
      console.log(response);
      setFeedbackSubmitted('SUCCESS');
    } catch (error) {
      setFeedbackSubmitted('ERROR');
    }
  }

  const rootDivClass = ['chat'];
  const messageDevClass = ['chat-bubble'];
  let avatar: IAvatar;
  let actor: string;

  switch (contentType) {
    case 'Question':
      rootDivClass.push('chat-end');
      messageDevClass.push('chat-bubble-success');
      actor = user?.given_name ? user.given_name : 'Customer';
      if (user?.family_name) {
        actor += ` ${user.family_name}`;
      }
      avatar = { imageSrc: customer, altText: actor };
      break;
    case 'Answer':
    default:
      rootDivClass.push('chat-start');
      messageDevClass.push('chat-bubble-info');
      actor = 'Aiah';
      avatar = { imageSrc: agent, altText: actor };
  }

  const formattedTimeStamp = dayjs(timeStamp).format('MMM DD HH:mm');
  const isChatEnd = rootDivClass.includes('chat-end');

  return (
    <motion.div
      className={rootDivClass.join(' ')}
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className={rootDivClass.join(' ')}>
        <div className="chat-image">
          <div className="avatar-img">
            <img src={avatar.imageSrc} alt={avatar.altText} />
          </div>
        </div>
        <div className="chat-content">
          <div className={`${isChatEnd ? 'chat-header-end' : 'chat-header-start'}`}>
            {actor}:<time className="chat-time"> {formattedTimeStamp}</time>
          </div>
          <div className={messageDevClass.join(' ')} dangerouslySetInnerHTML={{ __html: convertToHtml(message) }}></div>
          {isChatEnd && <div className="chat-footer">{state}</div>}

          {feedbackSubmitted === 'SUCCESS' ? (
            <div className="thumbs-container" style={{ opacity: 1 }}>
              {' '}
              {/* Make it visible */}
              Thank you. Your feedback has been submitted
            </div>
          ) : feedbackSubmitted === 'LOADING' ? (
            <div className="thumbs-container" style={{ opacity: 1 }}>
              {' '}
              {/* Make it visible */}
              <Spinner />
            </div>
          ) : (
            <div className="thumbs-container">
              {contentType === 'Answer' && (
                <SpaceBetween direction="horizontal" size="xs">
                  <Input
                    onChange={({ detail }) => setFeedback(detail.value)}
                    value={feedback}
                    inputMode="text"
                    placeholder="Optional feedback"
                  />
                  <Button
                    iconName="thumbs-up"
                    variant="icon"
                    onClick={() => {
                      setFeedbackSubmitted('LOADING');
                      handleThumbsClick(1);
                    }}
                  />
                  <Button
                    iconName="thumbs-down"
                    variant="icon"
                    onClick={() => {
                      setFeedbackSubmitted('LOADING');
                      handleThumbsClick(0);
                    }}
                  />
                </SpaceBetween>
              )}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
