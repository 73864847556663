import React, {useContext, useEffect, useRef} from 'react';

import {
    ChatWebSocketContext,
    ChatWebSocketContextType,
} from '../contexts/chatWebSocketContext';

import MessageBubble from './messageBubble';

export default function ChatBlock() {
    const {messages, onMessageState, ready} =
        useContext<ChatWebSocketContextType>(ChatWebSocketContext);

    // const [messages, setMessages] = useState<MessageInterfaceType | null>(null);
    const divRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        // Scroll to the bottom when new content is added
        if (divRef.current) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
        }
    }, [messages, onMessageState]);

    let dateTime = new Date()

    return (

        <div
            className="message-container"
            ref={divRef}
            style={{height: '50vh', overflow: 'auto'}}
        >
            {ready && (
                <MessageBubble
                    contentType="Intro"
                    message="Hi, I am Aiah. I am a genAI chatbot. Raising your little one can be a challenge for any parent. I am here to help you take the guess work out of parenting. 🥰"
                    state="Delivered"
                    timeStamp={dateTime}
                    messageId={''}
                    className="fade-in-text"
                />
            )}
            {messages &&
                Array.from(messages.values()).map((message: any) => (
                    <React.Fragment key={message.createdAt}>
                        <MessageBubble
                            contentType={message.messageType}
                            message={message.message}
                            state={message.state}
                            timeStamp={message.createdAt}
                            messageId={message.messageId}
                        />
                    </React.Fragment>
                ))}
            {onMessageState === 'IN_PROGRESS' && (
                <div className="dot-flashing"></div>
            )}
        </div>
    );
}
