import React from 'react';
import {
  Header
} from "@cloudscape-design/components";
import { WidgetConfig } from '../interfaces';
import GetWidgetData from "../../../../utils/getWidgetData";
import { usePeriod } from "../../periodContext";
import MinDocsTable from "./table";

export const getMissedMessages: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'mixedContent',
    title: 'Get Messages',
    description: 'Messages with 3 or less documents matched',
    header: GetMissedMessagesHeader,
    content: GetMissedMessages,
    staticMinHeight: 200,
  },
};

function GetMissedMessagesHeader() {
  return (
    <Header variant="h2" description="Messages that have been identified as having not enough good quality information in the prompt. The documents used for each of the messages should be reviewed">
      Low document coverage
    </Header>
  );
}

export default function GetMissedMessages() {
  const { period,audience } = usePeriod();
  const widgetParams = '&minDocuments=3'
  // @ts-ignore
  const { data, isLoading, error } = GetWidgetData({ templateType: "getMissedMessages", period, widgetParams, audience });

  return (
    <MinDocsTable isLoading={isLoading} error={error} userFeedback={data?.data}/>
  );
}
