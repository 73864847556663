export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Message id',
        key: 'id',
        groupValuesLabel: 'Message id values',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Rating',
        key: 'rating',
        groupValuesLabel: 'Rating, 0 = negative, 1 = positive',
        operators: ['=', '!='] as const,
    },
    {
        propertyLabel: 'Feedback',
        key: 'comment',
        groupValuesLabel: 'Feedback text',
        operators: [':', '!:'] as const,
    },
    {
        propertyLabel: 'Question',
        key: 'question',
        groupValuesLabel: 'Question text',
        operators: [':', '!:'] as const,
    },
    {
        propertyLabel: 'Audience Type',
        key: 'userType',
        groupValuesLabel: 'Audience type values',
        operators: [':', '!:', '=', '!='] as const,
    },
] as const;
