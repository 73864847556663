import {SideNavigationProps} from "@cloudscape-design/components/side-navigation";
import {BreadcrumbGroupProps} from "@cloudscape-design/components/breadcrumb-group";

export type NavigationItem = SideNavigationProps.Item;

export const items: NavigationItem[] = [
    {
        type: "section-group",
        title: "Aiah Testing",
        items: [
            {
                type: "link",
                text: "Gen AI Chatbot",
                href: "/chatbot-testing"
            }
        ]
    },
    {type: "divider"},
    {
        type: "section-group",
        title: "Dashboard",
        items: [
            {
                type: "link",
                text: "Dashboard",
                href: "/dashboard"
            }
        ]
    },
    {type: "divider"},
    {
        type: "section-group",
        title: "Configuration",
        items: [
            {
                type: "link",
                text: "Documents",
                href: "/documents"
            },
            {
                type: "link",
                text: "Prompt Engineering",
                href: "/prompt-engineering"
            }, {
                type: "link",
                text: "Rate Limiting",
                href: "/rate-limiting"
            },
        ]
    },
    {type: "divider"},
    {
        type: "section-group",
        title: "Administration",
        items: [
            {
                type: "link",
                text: "User Admin",
                href: "/user-admin"
            },
            {
                type: "link",
                text: "Audit Log",
                href: "/audit-log"
            }
        ]
    },
];

type BreadcrumbItem = BreadcrumbGroupProps['items'][0];
type BreadcrumbMapping = {
    [key: string]: BreadcrumbItem[];
};

const breadcrumbMapping: BreadcrumbMapping = {
    "/": [{text: "Home", href: "/"}],
    "/audit-log": [{text: "Audit Log", href: "/audit-log"}],
    "/user-admin": [{text: "User Admin", href: "/user-admin"}],
    "/user-admin/create": [{text: "Create", href: "/user-admin/create"}],
    "/prompt-engineering": [{text: "Prompt Engineering", href: "/prompt-engineering"}],
    "/prompt-engineering/create": [{text: "Create", href: "/prompt-engineering/create"}],
    "/prompt-engineering/edit": [{text: "Edit", href: "/prompt-engineering/edit"}],
    "/rate-limiting": [{text: "Rate Limiting", href: "/rate-limiting"}],
    "/rate-limiting/create": [{text: "Create", href: "/rate-limiting/create"}],
    "/rate-limiting/edit": [{text: "Edit", href: "/rate-limiting/edit"}],
    "/dashboard": [{text: "Dashboard", href: "/dashboard"}],
};

export function getBreadcrumbsForPath(path: string): BreadcrumbItem[] {
    const pathParts = path.split('/').filter(p => p);

    const breadcrumbItems = [];
    let currentPath = '';

    for (const part of pathParts) {
        currentPath += `/${part}`;

        if (breadcrumbMapping[currentPath]) {
            breadcrumbItems.push(...breadcrumbMapping[currentPath]);
        } else if (part.startsWith('edit')) {
            const itemBreadcrumbs = [...breadcrumbMapping["/prompt-engineering/edit"]];
            itemBreadcrumbs[2].href = currentPath;
            breadcrumbItems.push(...itemBreadcrumbs);
        }
    }

    return breadcrumbItems.length ? breadcrumbItems : [{text: "Home", href: "/"}];
}
