import React from 'react';
import {
    CollectionPreferences,
    StatusIndicator,
} from '@cloudscape-design/components';

const rawColumns = [
    {
        id: "id",
        header: "Id",
        cell: item => item.id || "-",
        sortingField: "id"
    },
    {
        id: "fileName",
        header: "File Name",
        cell: item => item.fileName || "-",
        sortingField: "fileName"
    },
    {
        id: "active",
        header: "Active",
        minWidth: 100,
        cell: item => item.active ? "Active" : <StatusIndicator type="warning">Not active</StatusIndicator>,
        sortingField: "active"
    },
    {
        id: "status",
        header: "Status",
        minWidth: 100,
        cell: item => item.status || "Unknown",
        sortingField: "status"
    },
    {
        id: "library",
        header: "Library",
        minWidth: 100,
        cell: item => item.library || "None",
        sortingField: "library"
    },
    {
        id: "createdAt",
        header: "Created",
        minWidth: 100,
        cell: item => item.createdAt || "-",
        sortingField: "createdAt"
    }
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));


const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});

const CONTENT_DISPLAY_OPTIONS = [
    {id: 'id', label: 'Id', alwaysVisible: true},
    {id: 'fileName', label: 'File Name', alwaysVisible: true},
    {id: 'active', label: 'Active', alwaysVisible: true},
    {id: 'status', label: 'Status', alwaysVisible: false},
    {id: 'library', label: 'Library', alwaysVisible: false},
    {id: 'createdAt', label: 'Status', alwaysVisible: false},
];

export const PAGE_SIZE_OPTIONS = [
    {value: 100, label: '100 documents'},
    {value: 303, label: '300 documents'},
    {value: 500, label: '500 documents'},
];

export const DEFAULT_PREFERENCES = {
    pageSize: 100,
    contentDisplay: [
        {id: 'id', visible: true},
        {id: 'fileName', visible: true},
        {id: 'active', visible: true},
        {id: 'status', visible: true},
        {id: 'library', visible: true},
        {id: 'createdAt', visible: true},
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: "comfortable",
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled = false,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
                            }) => (
    <CollectionPreferences
        title={"Preferences"}
        cancelLabel={"Cancel"}
        confirmLabel={"Confirm"}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({detail}) => setPreferences(detail)}
        pageSizePreference={{title: "Page size", options: pageSizeOptions}}
        wrapLinesPreference={{label: "Wrap lines", description: "Select to see all the text and wrap the lines"}}
        stripedRowsPreference={{label: "Striped rows", description: "Select to add alternating shaded rows"}}
        contentDensityPreference={{
            label: "Compact mode",
            description: "Select to display content in a denser, more compact mode"
        }}
        contentDisplayPreference={{title: "Select visible content / order", options: contentDisplayOptions}}
    />
);