import { useEffect, useState } from 'react';
import DateRangePicker from '@cloudscape-design/components/date-range-picker';
import { Popover } from '@cloudscape-design/components';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Box from '@cloudscape-design/components/box';

export default function CxDateRangePicker({ setDateRangeValue, rangeSelectorMode, defaults, limitDuration = true }) {
  const [value, setValue] = useState({ key: 'previous-6-hours', amount: 6, unit: 'hour', type: 'relative' });

  useEffect(() => {
    if (defaults && !defaults.startDate && !defaults.endDate) {
      setValue(defaults);
      return;
    }

    if (defaults === undefined || !defaults.startDate || !defaults.endDate) {
      return;
    }

    setValue({
      type: 'absolute',
      startDate: new Date(defaults.startDate).toISOString(),
      endDate: new Date(defaults.endDate).toISOString(),
    });
  }, [defaults]);

  const handleDateRangeChange = ({ detail }) => {

    let { value: rangeValue } = detail
    if (!detail.value) {
      rangeValue = {
        "amount": 1,
        "unit": "week",
        "type": "relative"
      }
    }
    setValue(rangeValue);
    setDateRangeValue(rangeValue);
  };


  const isValidRange = (range) => {
    if (range.type === 'absolute') {
      const startDate = new Date(range.startDate);
      const endDate = new Date(range.endDate);

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(startDate) || isNaN(endDate)) {
        return {
          valid: false,
          errorMessage: 'DateRangeIncomplete',
        };
      }

      if (startDate > endDate) {
        return {
          valid: false,
          errorMessage: 'DateRangeInvalid',
        };
      }

      if (limitDuration) {
        const maxDuration = 31 * 24 * 60 * 60 * 1000; // 31 days in milliseconds
        const duration = endDate.getTime() - startDate.getTime();

        if (duration > maxDuration) {
          return {
            valid: false,
            errorMessage: 'Date range exceeds 31 days',
          };
        }
      }
    }

    return { valid: true };
  };

  return (
    <SpaceBetween size="m" direction="horizontal">
      {defaults.type === 'relative' && (
        <Box color="text-status-info" display="inline">
          <Popover
            header="Live data"
            size="medium"
            triggerType="text"
            content={<>When using relative time ranges, data is automatically updated every 30 seconds.</>}
            renderWithPortal={true}
          >
            <Box color="text-status-info" fontSize="body-s" fontWeight="bold">
              Live data
            </Box>
          </Popover>
        </Box>
      )}
      <DateRangePicker
        onChange={handleDateRangeChange}
        value={value}
        {...(rangeSelectorMode ? { rangeSelectorMode } : {})}
        relativeOptions={[
          {
            key: 'previous-5-minutes',
            amount: 5,
            unit: 'minute',
            type: 'relative',
          },
          {
            key: 'previous-30-minutes',
            amount: 30,
            unit: 'minute',
            type: 'relative',
          },
          {
            key: 'previous-1-hour',
            amount: 1,
            unit: 'hour',
            type: 'relative',
          },
          {
            key: 'previous-6-hours',
            amount: 6,
            unit: 'hour',
            type: 'relative',
          },
        ]}
        isValidRange={isValidRange}
        placeholder={'Select data time ranges'}
      />
    </SpaceBetween>
  );
}
