import React from 'react';
import useSWR from 'swr';
import {
    SpaceBetween,
    SplitPanel,
    Spinner, Container, Box, ColumnLayout
} from '@cloudscape-design/components';
import fetcher from "../../../../../utils/fecther";

import {MissedMessagesItem} from "../../../../../lib/types";
import MinDocsSplitTable from "./table";

interface ExtendedUserFeedbackItem {
    selectedItems: MissedMessagesItem
}

const MinDocsSplitPanelDetails: React.FC<any> = (selectedItem: ExtendedUserFeedbackItem) => {
    const { data: response, error } = useSWR(
        `/queries?templateType=getMissedDocuments&cosineSimilarityThresholdMin=0&cosineSimilarityThresholdMax=1&messageId=${selectedItem.selectedItems.chatMessageId}`,
        fetcher, {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshInterval: 0,
        }
    );

    if (error) return <div>Error loading data</div>;
    if (!response) return <Spinner />; // assuming Spinner is your loading component

    // @ts-ignore
    const ValueWithLabel = ({label, children}) => (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );

    return (
        <SplitPanel header={`Low document coverage for message id: ${selectedItem.selectedItems.chatMessageId}`}
                    closeBehavior="collapse">
            <SpaceBetween size="l">

            <Container>
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel
                            label="No. of documents">{selectedItem.selectedItems.numDocsUsed}</ValueWithLabel>
                        <ValueWithLabel label="Avg Tokens">
                            {parseFloat(selectedItem.selectedItems.averageTokenCount).toFixed(2)}
                        </ValueWithLabel>
                        <ValueWithLabel label="total Tokens">
                            {selectedItem.selectedItems.totalTokenCount}
                        </ValueWithLabel>
                        <ValueWithLabel label="Audience">
                            {selectedItem.selectedItems.userType}
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel
                            label="Question">{selectedItem.selectedItems.question}</ValueWithLabel>
                        <ValueWithLabel label="Answer">
                            {selectedItem.selectedItems.answer}
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
                <MinDocsSplitTable data={response?.data}/>
                <>{" "}</>
            </SpaceBetween>
        </SplitPanel>
    );
};

export default MinDocsSplitPanelDetails;
