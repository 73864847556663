import React, { useState, useContext, useEffect } from 'react';
import Input from '@cloudscape-design/components/input';
import FormField from '@cloudscape-design/components/form-field';
import Alert from '@cloudscape-design/components/alert';

import {
  ChatWebSocketContext,
  ChatWebSocketContextType,
  IMessageInterface,
  // @ts-ignore
} from '../contexts/chatWebSocketContext.tsx';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Box from '@cloudscape-design/components/box';
import { Popover, StatusIndicator } from '@cloudscape-design/components';
import Button from '@cloudscape-design/components/button';

export default function QuestionBar() {
  const [question, setQuestion] = useState('');
  const { conversationId, promptIntro, promptConfig, ready, value, socket, updateMessages } =
    useContext<ChatWebSocketContextType>(ChatWebSocketContext);

  const sendMessage = () => {
    if (question.trim() === '') {
      return;
    }
    // return back to ready when websocket available

    if (socket) {
      const messageObject: IMessageInterface = {
        messageType: 'Question',
        message: question,
        state: 'Sent',
        createdAt: new Date(),
        conversationId: conversationId,
      };
      socket.send(
        JSON.stringify({
          sessionId: '',
          userId: '',
          action: 'sendQuestion',
          questionText: messageObject.message,
          promptIntro: promptIntro ? promptIntro : '',
          promptConfig: promptConfig,
          userType: 'Admin',
          state: 'IN_PROGRESS',
          conversationId: messageObject.conversationId,
        })
      );
      updateMessages(messageObject);
      setQuestion('');
    } else {
    }
  };

  const sendKeyboardMessage = (event: any) => {
    if (event.detail.key === 'Enter') {
      sendMessage();
    }
  };
  //
  useEffect(() => {}, [ready, value]);

  if (!ready) {
    return (
      <Alert statusIconAriaLabel="Info" header="Connectivity">
        No connection
      </Alert>
    );
  }

  const quesitonLimit = 1000;

  return (
    <SpaceBetween size="l">
      <FormField
        stretch
        errorText={question.length > quesitonLimit && 'The name has too many characters.'}
        constraintText={
          <>
            Character count: {question.length}/ {quesitonLimit}
            <span className="custom-wrapping">
              <Box margin={{ right: 'xxs' }} display="inline-block">
                <Popover
                  size="small"
                  position="top"
                  triggerType="custom"
                  dismissButton={false}
                  content={<StatusIndicator type="success">Conversation Id copied</StatusIndicator>}
                >
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    ariaLabel="Copy Conversation Id"
                    onClick={() => {
                      navigator.clipboard.writeText(conversationId);
                    }}
                  />
                </Popover>
              </Box>
              {conversationId}
            </span>
          </>
        }
      >
        <Input
          name="aiah-question"
          onChange={(e: any) => setQuestion(e.detail.value)}
          value={question}
          placeholder="Enter your message"
          type="search"
          onKeyDown={sendKeyboardMessage}
        />
      </FormField>
    </SpaceBetween>
  );
}
