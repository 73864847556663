import React from 'react';
import useSWR from 'swr';
import {
    SplitPanel, Spinner
} from '@cloudscape-design/components';
import fetcher from "../../../../utils/fecther";
import Conversation from "../../../../components/conversationBubbles/messages";
import { UserFeedbackItem } from "../../../../lib/types";

interface ExtendedUserFeedbackItem {
    selectedItems: UserFeedbackItem
}

const UserFeedbackSplitPanelDetails: React.FC<any> = (selectedItem: ExtendedUserFeedbackItem) => {

    // ${selectedItem.selectedItems.conversationId}
    const {data, isLoading, error} = useSWR(`/queries?templateType=getUserFeedbackCommentsId&conversationId=${selectedItem.selectedItems.conversationId}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });

    if (isLoading || !data || error) {
        return (
            <SplitPanel header={`User Comment Detail:`} closeBehavior="collapse">
                {error ? <>{error.info.message}</> : <Spinner/>}
            </SplitPanel>
        )
    }

    const conversation: UserFeedbackItem[] = data.data.slice().reverse();

  return (
    <SplitPanel header={`Conversation id: ${selectedItem.selectedItems.id}`} closeBehavior="hide">
        <Conversation messages={conversation} />
    </SplitPanel>
  );
};

export default UserFeedbackSplitPanelDetails;
