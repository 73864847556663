import React, {useState} from "react";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import ButtonDropdown, {ButtonDropdownProps} from "@cloudscape-design/components/button-dropdown";
import {Pagination, Spinner, Table} from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from "@cloudscape-design/components/alert";
import {getTextFilterCounterText} from "../../../utils/text-filter";
import {useCollection} from '@cloudscape-design/collection-hooks';
import {useLocalStorage} from "../../../components/use-local-storage";
import {useColumnWidths} from "../../../components/table/use-column-width";
import {useFlash} from "../../../utils/hooks/useFlash";
import {FILTERING_PROPERTIES as filteringProperties} from "./filtering_poperties"
import {mutate} from 'swr';
import {Rates, UserTableProps} from "../types";
import {useSplitPanelControl} from "../../../utils/hooks/splitPanelContext";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from 'uuid';

import {
    DEFAULT_PREFERENCES,
    EDITABLE_COLUMN_DEFINITIONS,
    Preferences,
} from './table.config'


import {
    TableEmptyState,
    TableNoMatchState,
} from '../../../components/table/commons'
import fetcher from "../../../utils/fecther";


export default function RateLimitTable({isLoading, rates = [], error}: UserTableProps) {
    // State
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState<Rates[]>([]);
    const [actionLoading, setActionLoading] = useState(false)
    const [columnDefinitions, saveWidths] = useColumnWidths('Aiah-Rate-Limiting-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS)
    const [preferences, setPreferences] = useLocalStorage('Aiah-Rate-Limiting-TableEditable-Preferences', DEFAULT_PREFERENCES);

    // Hooks
    const {setSplitPanelOpen, setSplitPanelContent,} = useSplitPanelControl();
    const navigate = useNavigate();
    const {addFlash, removeFlash} = useFlash();

    const {items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps} = useCollection<any>(
        rates,
        {
            propertyFiltering: {
                filteringProperties,
                empty: <TableEmptyState resourceName="Rate Limits" urlPath={"rate-limiting"}/>,
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
            },
            filtering: {
                empty: <TableEmptyState resourceName="Rate Limits" urlPath={"rate-limiting"}/>,
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
            },
            pagination: {pageSize: preferences.pageSize},
            sorting: {},
            selection: {},
        }
    );

    // This is used to handle button presses
    const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {

        const details = event.detail;
        if (details.id === "edit") {
            navigate(`/rate-limiting/edit/${selectedItems[0].version}`);

        }
        if (details.id === "delete") {
            setActionLoading(true)

            try {
                await fetcher(`/rate-limit/${selectedItems[0].version}`, 'DELETE');
                const messageId = uuidv4()
                addFlash({
                    type: "success",
                    content: `Successfully deleted ${selectedItems[0].version}`,
                    dismissible: true,
                    dismissLabel: "Dismiss",
                    onDismiss: () => removeFlash(messageId),
                    id: messageId
                });
                setSelectedItems([])
                setSplitPanelOpen(false)
                setSplitPanelContent(null)
            } catch {
                const messageId = uuidv4()
                addFlash({
                    type: "error",
                    content: `Error deleting ${selectedItems[0].version}`,
                    dismissible: true,
                    dismissLabel: "Dismiss",
                    onDismiss: () => removeFlash(messageId),
                    id: messageId
                });
            } finally {
                await mutate('/rate-limit');
                setActionLoading(false)
            }
        }

    };

    const onRefresh = async () => {
        setRefreshLoading(true)
        await mutate('/rate-limit');
        setRefreshLoading(false)
    };

    const refreshButtonProps = {onClick: onRefresh};


    return (
        <>
            <Table
                {...collectionProps}
                stickyHeader={true}
                resizableColumns={true}
                onColumnWidthsChange={saveWidths}
                onSelectionChange={({detail: {selectedItems}}) => {
                    if (selectedItems && selectedItems.length > 0) {
                        const selectedItem: Rates = selectedItems[0];
                        setSelectedItems(() => [selectedItem]);
                    }
                }}
                selectedItems={selectedItems as any}
                columnDefinitions={columnDefinitions}
                items={items}
                trackBy="version"
                loadingText="Loading rate limtis"
                columnDisplay={preferences.contentDisplay}
                wrapLines={preferences.wrapLines}
                stripedRows={preferences.stripedRows}
                // stickyColumns={preferences.stickyColumns} // Do not use, causes react resizer error. Leaving in comment to avoid accidental issues.
                contentDensity={preferences.contentDensity as "compact" | "comfortable"}
                selectionType={"single"}
                loading={isLoading || !rates || refreshLoading}
                header={
                    <Header
                        variant="awsui-h1-sticky"
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                <Button iconName="refresh" ariaLabel="Refresh" {...refreshButtonProps} />
                                <ButtonDropdown
                                    disabled={!selectedItems || selectedItems.length === 0}
                                    onItemClick={handleUtilityItemClick}
                                    loading={actionLoading}
                                    loadingText={"loading"}
                                    items={[
                                        {
                                            text: "Edit",
                                            id: "edit",
                                            disabled: false
                                        },
                                        {
                                            text: "Delete",
                                            id: "delete",
                                            disabled: false
                                        },
                                    ]}
                                >
                                    Actions
                                </ButtonDropdown>
                                <Button variant="primary" onClick={() => navigate('/rate-limiting/create')}>
                                    Create limit
                                </Button>

                            </SpaceBetween>
                        }
                    >
                        Rate Limits {rates?.length && !isLoading ? `(${rates.length})` : <Spinner/>}
                    </Header>
                }
                filter={
                    <PropertyFilter
                        {...propertyFilterProps}
                        countText={getTextFilterCounterText(filteredItemsCount)}
                        expandToViewport={true}
                        filteringAriaLabel={"Filter rate limits"}
                        filteringPlaceholder={"Filter rate limits"}
                    />
                }
                pagination={<Pagination {...paginationProps} />}
                preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
            />
            {error &&
                <Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header="Error getting data"
                >
                    Refresh the page to try again.
                </Alert>}
        </>
    );
}