export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Message id',
        key: 'id',
        groupValuesLabel: 'Message id values',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'NumDocsUsed',
        key: 'numDocsUsed',
        groupValuesLabel: 'Number of documents used',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Question',
        key: 'question',
        groupValuesLabel: 'Question text',
        operators: [':', '!:'] as const,
    },
    {
        propertyLabel: 'Answer',
        key: 'answer',
        groupValuesLabel: 'Answer',
        operators: [':', '!:'] as const,
    },
] as const;
