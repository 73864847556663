import React from 'react';
import { CollectionPreferences } from "@cloudscape-design/components";


const rawColumns = [
    {
        id: "segmentedContentId",
        header: "segmentedContentId",
        cell: item => item.segmentedContentId || "-",
        sortingField: "segmentedContentId",
        width: 150
    },
    {
        id: "cosineSimilarity",
        header: "cosineSimilarity",
        cell: item => item.cosineSimilarity || 0,
        sortingField: "cosineSimilarity",
        width: 150
    },
    {
        id: "originalFilename",
        header: "originalFilename",
        cell: item => item.originalFilename || "-",
        sortingField: "originalFilename",
        width: 100
    },
    {
        id: "segmentFilename",
        header: "segmentFilename",
        cell: item => item.segmentFilename || "-",
        sortingField: "segmentFilename",
        width: 100
    },
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));


const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});

const CONTENT_DISPLAY_OPTIONS = [
    {id: 'segmentedContentId', label: 'segmentedContentId', alwaysVisible: true},
    {id: 'cosineSimilarity', label: 'cosineSimilarity', alwaysVisible: false},
    {id: 'originalFilename', label: 'originalFilename', alwaysVisible: false},
    {id: 'segmentFilename', label: 'segmentFilename', alwaysVisible: false},
];

export const PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10 items'},
    {value: 30, label: '30 items'},
    {value: 50, label: '50 items'},
];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
        {id: 'segmentedContentId', visible: true},
        {id: 'cosineSimilarity', visible: true},
        {id: 'originalFilename', visible: true},
        {id: 'segmentFilename', visible: true},
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: "comfortable",
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled = false,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
                            }) => (
    <CollectionPreferences
        title={"Preferences"}
        cancelLabel={"Cancel"}
        confirmLabel={"Confirm"}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({detail}) => setPreferences(detail)}
        pageSizePreference={{title: "Page size", options: pageSizeOptions}}
        wrapLinesPreference={{label: "Wrap lines", description: "Select to see all the text and wrap the lines"}}
        stripedRowsPreference={{label: "Striped rows", description: "Select to add alternating shaded rows"}}
        contentDensityPreference={{
            label: "Compact mode",
            description: "Select to display content in a denser, more compact mode"
        }}
        contentDisplayPreference={{title: "Select visible content / order", options: contentDisplayOptions}}
    />
);