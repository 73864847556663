import React, {useState} from 'react';
import useSWR from 'swr';
import fetcher from "../../utils/fecther";
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {ContentLayout} from '@cloudscape-design/components';
import AuditTable from "./components/table";


export default function AuditLog() {

    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const [now] = useState(Date.now());
    const [start, setStart] = useState<number | null>(now - oneWeekInMilliseconds);
    const [end, setEnd] = useState<number | null>(now);


    const handleDateRangeChange = (range: any) => {
        if (range && range.startDate && range.endDate) {
            setStart(new Date(range.startDate).getTime());
            setEnd(new Date(range.endDate).getTime());
        } else {
            // reset start and end to some default or null values if required
            setStart(null);
            setEnd(null);
        }
    };

    const fetchUrl = (start && end) ? `/audit?start=${start}&end=${end}` : null;
    const {data, isLoading, error} = useSWR(fetchUrl, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
        shouldRetryOnError: false,
    });

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    description="Search and view changes that have been made within the Aiah application"
                >
                    System audit logs
                </Header>
            }
        >
            <SpaceBetween size="m">
                <AuditTable isLoading={isLoading}
                            error={error}
                            auditLogs={data?.data}
                            onDateRangeChange={handleDateRangeChange}
                            defaultStart={start}
                            defaultEnd={end}/>
            </SpaceBetween>
        </ContentLayout>
    );
}

