import React, {useState} from 'react';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {ContentLayout} from '@cloudscape-design/components';
import ChatBlock from "./components/containers/chatBlock";
import QuestionBar from "./components/containers/questionBar";
import {Container, Button} from "@cloudscape-design/components";
import {ChatWebSocketProvider} from "./components/contexts/chatWebSocketContext";
import SettingsModal from "./settingsModal";
import PoweredBy from "../../components/poweredBy";


export default function ChatbotTesting() {
    const [visible, setVisible] = useState(false);

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    description="Test out your chatbot configuration"
                >
                    Chatbot testing
                </Header>
            }
        >
            <SpaceBetween size="m">
                <div className="flex items-center justify-center">
                    <div className="mt-12 h-5/6 max-w-2xl rounded-2xl border border-accent p-2 shadow-2xl">
                        <ChatWebSocketProvider>
                            <Container
                                header={
                                    <Header
                                        variant="h2"
                                        description="I am here to help you take the guess work out of parenting."
                                        actions={
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="xs"
                                            >
                                                <Button iconName="settings" variant="icon"
                                                        onClick={() => setVisible(true)}/>
                                            </SpaceBetween>
                                        }
                                    >
                                        Aiah
                                    </Header>
                                }
                                footer={<PoweredBy/>}
                            >
                                <ChatBlock/>
                                <QuestionBar/>
                                <SettingsModal visible={visible} setVisible={setVisible}/>
                            </Container>
                        </ChatWebSocketProvider>
                    </div>
                </div>
            </SpaceBetween>
        </ContentLayout>
    );
}

