import {Attribute} from "../types";

export function getAttributeValue(attributes: Attribute[], name: string): string | undefined {
    const attribute = attributes.find(attr => attr.Name === name);
    return attribute?.Value;
}

export function getStatusIndicatorType(userStatus: string): 'warning' | 'success' | 'error' {
    switch (userStatus) {
        case "UNCONFIRMED":
            return "warning";
        case "CONFIRMED":
            return "success";
        default:
            return "error";
    }
}

export function getAccountStatusIndicatorType(userStatus: boolean) {
    switch (userStatus) {
        case false:
            return "error";
        default:
            return "success";
    }
}