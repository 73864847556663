import React from 'react';
import { Header, LineChart } from '@cloudscape-design/components';
import { commonChartProps, dateTimeFormatter } from '../chart-commons';
import { WidgetConfig } from '../interfaces';
import GetWidgetData from '../../../../utils/getWidgetData';
import { usePeriod } from '../../periodContext';

interface IGetRevertsApiData {
  avg_message_count: string;
  userType: string;
  interval_start: string;
}

interface ITransformedDataObject {
  x: Date;
  y: number;
}

interface ITransformedGroupedData {
  [key: string]: {
    //interval_start
    [key: string]: ITransformedDataObject;
  };
}

interface ITransformedData {
  groupedData: ITransformedGroupedData;
  consolidatedData: {
    [key: string]: ITransformedDataObject;
  };
}

export const getReverts: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'lineChart',
    title: 'Get Reverts',
    description: 'Volume of chat reverts',
    header: GetRevertsHeader,
    content: GetReverts,
    staticMinHeight: 200,
  },
};

function GetRevertsHeader() {
  return (
    <Header variant="h2" description="Volume of chat reverts">
      Avg messages per conversation
    </Header>
  );
}

function transformDataForLineChart(apiData: IGetRevertsApiData[]) {
  if (!apiData || apiData.length === 0) {
    return { groupedData: {}, consolidatedData: {} };
  }

  // split the data into two groups.
  // groupedData: will have the data for the different users
  // consolidatedData: Will have all the time points for the report
  const chartData: ITransformedData = { groupedData: {}, consolidatedData: {} };

  for (const row of apiData) {
    if (row.userType !== null) {
      if (!chartData.groupedData[row.userType]) {
        chartData.groupedData[row.userType] = {};
      }
      const gd = chartData.groupedData[row.userType];
      gd[row.interval_start] = addData(row, gd[row.interval_start] || {});
    }
    chartData.consolidatedData[row.interval_start] = addData(row, chartData.consolidatedData[row.interval_start] || {});
  }

  const groupedData = chartData.groupedData;
  const cd = chartData.consolidatedData;
  //Now fill the groupData point with any missing time points,
  for (const k in cd) {
    for (const group in groupedData) {
      if (!groupedData[group][k]) {
        groupedData[group][k] = {
          x: cd[k].x,
          y: 0,
        };
      }
    }
  }
  return chartData;
}

function addData(row: IGetRevertsApiData, chartData: ITransformedDataObject) {
  if (Object.keys(chartData).length > 0 && chartData.y) {
    chartData.y = chartData.y + parseInt(row.avg_message_count, 10);
    return chartData;
  } else {
    return {
      x: new Date(row.interval_start),
      y: parseInt(row.avg_message_count, 10),
    };
  }
}

function valueFormatter(e: number) {
  return e.toString(); // converting number to string
}

/**
 * Creates an array for Object for the graph. based on the number of user data you get back from API
 *
 * @param seriesData
 */
function getChartSeries(seriesData: ITransformedGroupedData): any[] {
  const chartSeries: {
    type: 'line' | 'bar' | 'threshold';
    title: string;
    data: any;
    valueFormatter: (e: number) => string;
  }[] = [];

  const keys = Object.keys(seriesData).sort((a, b) =>
    a.toLowerCase() > b.toLowerCase() ? 1 : b.toLowerCase() > a.toLowerCase() ? -1 : 0
  );

  for (const k of keys) {
    const data = seriesData[k];
    const allData = Object.values(data);
    chartSeries.push({
      title: `${k} Messages`,
      type: 'line',
      data: allData.sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)),
      valueFormatter,
    });
  }
  return chartSeries.length > 0
    ? chartSeries
    : [
        {
          title: `Messages`,
          type: 'line',
          data: [],
          valueFormatter,
        },
      ];
}

export default function GetReverts() {
  const { period, audience } = usePeriod();
  const widgetParams = `&cosineSimilarityThresholdMin=0.8&cosineSimilarityThresholdMax=1`;

  // @ts-ignore
  const { data, isLoading, error } = GetWidgetData({ templateType: 'getReverts', period, widgetParams, audience });

  const transformedData = transformDataForLineChart(data?.data);
  const { groupedData, consolidatedData } = transformedData;

  const allData = Object.values(consolidatedData);
  const users = Object.keys(groupedData);
  // Calculate yDomain based on data
  const yMax = allData.reduce((max, d) => Math.max(max, d.y), 0);
  const yDomain = [0, yMax * 1.1]; // adding 10% padding to the maximum value

  // Calculate xDomain based on data
  const xMin = allData.length > 0 ? allData[0].x : new Date();
  const xMax = allData.length > 0 ? allData[allData.length - 1].x : new Date();
  const xDomain = [xMin, xMax];

  const seriesData = getChartSeries(groupedData);

  // @ts-ignore
  return (
    <LineChart
      {...commonChartProps}
      hideFilter={true}
      fitHeight={true}
      statusType={isLoading ? 'loading' : error ? 'error' : 'finished'}
      height={150}
      series={seriesData}
      yDomain={yDomain}
      xDomain={xDomain}
      xScaleType="time"
      xTitle="Time (UTC)"
      yTitle="Avg messages per conversation over time"
      ariaLabel="Avg messages per conversation"
      ariaDescription={`Line chart showing Avg messages per conversation`}
      i18nStrings={{
        ...commonChartProps.i18nStrings,
        filterLabel: 'Filter displayed data',
        filterPlaceholder: 'Filter data',
        xTickFormatter: dateTimeFormatter,
      }}
    />
  );
}
