import React from 'react';
import {Link} from "react-router-dom";
import {
    CollectionPreferences, StatusIndicator,
} from '@cloudscape-design/components';


const rawColumns = [
    {
        id: "version",
        header: "Name",
        cell: item => <Link to={`/rate-limiting/edit/${item.version}`}>{item.version}</Link> || "-",
        sortingField: "version"
    },
    {
        id: "period",
        header: "period",
        cell: item => item.data.period || "-",
    },
    {
        id: "rateLimit",
        header: "rateLimit",
        cell: item => item.data.rateLimit || "-",
    },
    {
        id: "status",
        header: "status",
        cell: item => item.data.status ? "Active" : <StatusIndicator type="warning">Not active</StatusIndicator>,
    },
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));


const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});

const CONTENT_DISPLAY_OPTIONS = [
    {id: 'version', label: 'version', alwaysVisible: true},
    {id: 'period', label: 'period', alwaysVisible: true},
    {id: 'rateLimit', label: 'rateLimit', alwaysVisible: true},
    {id: 'status', label: 'status', alwaysVisible: true},
];

export const PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10 Prompts'},
    {value: 30, label: '30 Prompts'},
    {value: 50, label: '50 Prompts'},
];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
        {id: 'version', visible: true},
        {id: 'period', visible: true},
        {id: 'rateLimit', visible: true},
        {id: 'status', visible: true},
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: "comfortable",
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled = false,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
                            }) => (
    <CollectionPreferences
        title={"Preferences"}
        cancelLabel={"Cancel"}
        confirmLabel={"Confirm"}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({detail}) => setPreferences(detail)}
        pageSizePreference={{title: "Page size", options: pageSizeOptions}}
        wrapLinesPreference={{label: "Wrap lines", description: "Select to see all the text and wrap the lines"}}
        stripedRowsPreference={{label: "Striped rows", description: "Select to add alternating shaded rows"}}
        contentDensityPreference={{
            label: "Compact mode",
            description: "Select to display content in a denser, more compact mode"
        }}
        contentDisplayPreference={{title: "Select visible content / order", options: contentDisplayOptions}}
    />
);