import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import {I18nProvider} from '@cloudscape-design/components/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Theme, applyTheme } from '@cloudscape-design/components/theming';
import customTheme from './themes/customTheme'
// Import all locales
import messages from '@cloudscape-design/components/i18n/messages/all.all';

// Auth
import { Amplify } from "aws-amplify";
import {
    Authenticator,
    Heading,
    Text,
    useTheme,
    View,
    useAuthenticator,
    Button,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Import Routes here
import Root from './routes/root';
import Dashboard from './routes/dashboard';
import UserAdmin from './routes/user-admin';
import ErrorPage from './error-page';
import Documents from './routes/documents';
import AuditLog from './routes/audit-log';
import UserAdminCreate from './routes/user-admin/create';
import ChatbotTesting from './routes/chatbot-testing';
import PromptEngineeringCreate from './routes/prompt-engineering/create';
import PromptEngineeringEdit from './routes/prompt-engineering/edit';
import PromptEngineering from './routes/prompt-engineering';
import DocumentCreate from './routes/documents/create';
import RateLimiting from './routes/rate-limiting';
import RateLimitingEdit from './routes/rate-limiting/edit';
import RateLimitingCreate from './routes/rate-limiting/create';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    mandatorySignIn: true,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      responseType: 'code',
    },
  },
});

const components = {
  Header() {
    return (
      <View textAlign="center">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <img alt={'parent sense logo'} src={'/parentsense-logo.svg'} width={250} height={50} />
        </div>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        No unauthorised access
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Aiah
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
            Reset Password
          </Button>
        </View>
      );
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.large} 0 0 ${tokens.space.large}`} level={3}>
          Register OTP
        </Heading>
      );
    },
    Footer() {
      return <Text>TIP: Scan QR using Google Authenticator mobile app</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.large} 0 0 ${tokens.space.large}`} level={3}>
          One time passcode:
        </Heading>
      );
    },
    Footer() {
      return <Text>TIP: Google Authenticator</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.large} 0 0 ${tokens.space.large}`} level={3}>
          Password reset:
        </Heading>
      );
    },
    Footer() {
      return <Text />;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.large} 0 0 ${tokens.space.large}`} level={3}>
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};
const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email:',
      label: '',
    },
    password: {
      label: '',
      placeholder: 'Enter your Password:',
    },
  },

  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
      label: '',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your email Code:',
      // label: 'New Label',
      isRequired: true,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: `Aiah ${process.env.REACT_APP_ENV === 'prod' ? '' : process.env.REACT_APP_ENV}`,
    },
    confirmation_code: {
      label: '',
      placeholder: 'Enter your code:',
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: '',
      placeholder: 'Enter your google authenticator Code:',
      isRequired: true,
    },
  },
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "dashboard",
                element: <Dashboard/>,
            },
            {
                path: "documents",
                element: <Documents/>,
            },
            {
                path: "documents/create",
                element: <DocumentCreate/>,
            },
            {
                path: "prompt-engineering",
                element: <PromptEngineering/>,
            },
            {
                path: "prompt-engineering/edit/:featureId",
                element: <PromptEngineeringEdit/>,
            },
            {
                path: "prompt-engineering/create",
                element: <PromptEngineeringCreate/>,
            },
            {
                path: "rate-limiting",
                element: <RateLimiting/>,
            },
            {
              path: "rate-limiting/edit/:featureId",
              element: <RateLimitingEdit/>,
            },
            {
              path: "rate-limiting/create",
              element: <RateLimitingCreate/>,
            },
            {
                path: "chatbot-testing",
                element: <ChatbotTesting/>,
            },
            {
                path: "user-admin",
                element: <UserAdmin/>,
            },
            {
                path: "user-admin/create",
                element: <UserAdminCreate/>,
            },
            {
                path: "audit-log",
                element: <AuditLog/>,
            },
            {
                path: "*",
                element: <ErrorPage/>
            },
        ],
    },
]);

const theme: Theme = customTheme
// Apply custom theme
applyTheme({ theme });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <I18nProvider locale="en" messages={[messages]}>
            <Authenticator hideSignUp formFields={formFields} components={components}>
                <RouterProvider router={router}/>
            </Authenticator>
        </I18nProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
