import React, {useEffect, useState} from 'react';
import AppLayout, {AppLayoutProps} from '@cloudscape-design/components/app-layout';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import {ButtonDropdownProps} from "@cloudscape-design/components/button-dropdown";
import {signOut} from "./signout";
import {UserProfile, fetchUserProfile} from '../../components/auth/userProfile'
import {useSplitPanelControl} from "../../utils/hooks/splitPanelContext";
import packageJson from '../../../package.json'
import './styles.css';
import PoweredBy from "../../components/poweredBy";

export interface ShellProps {
    breadcrumbs?: AppLayoutProps['breadcrumbs'];
    contentType?: Extract<AppLayoutProps.ContentType, 'default' | 'table' | 'form'>;
    tools?: AppLayoutProps['tools'];
    children?: AppLayoutProps['content'];
    navigation?: AppLayoutProps['navigation'];
    notifications?: AppLayoutProps['notifications'];
    profile?: UserProfile | null;
}


export default function Shell({
                                  children,
                                  contentType,
                                  breadcrumbs,
                                  tools,
                                  navigation,
                                  notifications
                              }: ShellProps) {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const {
    splitPanelSize,
    splitPanelOpen,
    splitPanelPreferences,
    onSplitPanelResize,
    onSplitPanelToggle,
    onSplitPanelPreferences,
    splitPanelContent
  } = useSplitPanelControl();

  useEffect(() => {
    async function getUserProfile() {
      const profileData = await fetchUserProfile();
      setProfile(profileData);
    }

    getUserProfile();
  }, []);

    const handleUtilityItemClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
        const details = event.detail;

        if (details.id === 'sign-out') {
            console.log("logout clicked");
            signOut();
        }
    };


    return (
        <>
            <div id='top-nav'>
                <TopNavigation
                    identity={{
                        href: '/',
                        title: 'Sense-IT Chatbot',

                    }}
                    i18nStrings={{
                        overflowMenuTriggerText: 'More',
                        overflowMenuTitleText: 'All'
                    }}
                    utilities={[
                      {
                        type: 'menu-dropdown',
                        text: `${profile?.given_name} ${profile?.family_name}`,
                        description: profile?.email,
                        iconName: 'user-profile',
                        items: [
                          {id: 'sign-out', text: 'Sign out'}
                        ],
                        onItemClick: handleUtilityItemClick
                      }
                    ]}
                />
            </div>
            <AppLayout
                contentType={contentType}
                navigation={navigation}
                breadcrumbs={breadcrumbs}
                notifications={notifications}
                stickyNotifications={true}
                tools={tools}
                content={children}
                footerSelector='#footer'
                headerSelector='#top-nav'
                splitPanelOpen={splitPanelOpen}
                onSplitPanelToggle={onSplitPanelToggle}
                splitPanelSize={splitPanelSize}
                onSplitPanelResize={onSplitPanelResize}
                splitPanelPreferences={splitPanelPreferences as any}
                onSplitPanelPreferencesChange={onSplitPanelPreferences}
                splitPanel={splitPanelContent}
                ariaLabels={{
                    navigation: 'Navigation drawer',
                    navigationClose: 'Close navigation drawer',
                    navigationToggle: 'Open navigation drawer',
                    notifications: 'Notifications',
                    tools: 'Help panel',
                    toolsClose: 'Close help panel',
                    toolsToggle: 'Open help panel'
                }}
            />
            <div id="footer">
                <div className="footer-left">
                    Version {packageJson.version}
                </div>
              <PoweredBy/>
            </div>
        </>
    );
}
