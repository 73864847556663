import React, {ReactNode} from 'react';
import {
    Box,
    ColumnLayout,
    Container,
    Textarea,
    SpaceBetween,
    SplitPanel,
    Tabs,
} from '@cloudscape-design/components';


interface PromptSplitPanelDetailsProps {
    selectedItems: any;
}

interface ValueWithLabelProps {
    label: string;
    children: ReactNode;
}

const ValueWithLabel: React.FC<ValueWithLabelProps> = ({label, children}) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);


const PromptSplitPanelDetails: React.FC<PromptSplitPanelDetailsProps> = ({selectedItems}) => {

    let event = selectedItems?.detail[0]

    const tab1 =
        <Container>
            <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel label="Gen AI Model">
                        {event?.data?.genAIModel}
                    </ValueWithLabel>
                    <ValueWithLabel label="Embedding Model">
                        {event?.data?.embeddingModel}
                    </ValueWithLabel>
                    <ValueWithLabel label="Summary Model">
                        {event?.data?.summaryModel}
                    </ValueWithLabel>
                    <ValueWithLabel label="Summary Enabled">
                        {event?.data?.summaryStatus}
                    </ValueWithLabel>
                    <ValueWithLabel label="Dynamic Prompt Enabled">
                        {event?.data?.dynamicPromptStatus}
                    </ValueWithLabel>
                    <ValueWithLabel label="Embedding Search Enabled">
                        {event?.data?.embeddingSearchStatus}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Document limit">{event?.data?.documentLimit}</ValueWithLabel>
                    <ValueWithLabel label="Token Budget">
                        {event?.data?.tokenBudget}
                    </ValueWithLabel>
                    <ValueWithLabel label="Similarity Threshold">
                        {event?.data?.similarityThreshold}
                    </ValueWithLabel>
                    <ValueWithLabel label="genAICompletionTokenLimit">
                        {event?.data?.genAICompletionTokenLimit}
                    </ValueWithLabel>
                    <ValueWithLabel label="summaryCompletionTokenLimit">
                        {event?.data?.summaryCompletionTokenLimit}
                    </ValueWithLabel>
                    <ValueWithLabel label="dynamicPromptCompletionTokenLimit">
                        {event?.data?.dynamicPromptCompletionTokenLimit}
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>

    const tab2 = <Textarea
        value={event.data.prompt}
        rows={12}
    />

    return (
        <SplitPanel header={`Prompt: ${event.version}`} closeBehavior="collapse">
            <Tabs
                tabs={[
                    {
                        label: "Settings",
                        id: "first",
                        content: tab1
                    },
                    {
                        label: "Prompt",
                        id: "second",
                        content: tab2
                    }
                ]}
            />
        </SplitPanel>
    );
}

export default PromptSplitPanelDetails;


