import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Flashbar from "@cloudscape-design/components/flashbar";
import Shell from "../layouts/shell";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Navigation from "../components/navigation";
import React, {useEffect, useState} from "react";
import {getBreadcrumbsForPath } from "../components/navigation/navigationMapping";
import {FlashProvider} from "../utils/hooks/useFlash";
import {useFlash} from "../utils/hooks/useFlash";
import {SplitPanelProvider, useSplitPanelControl} from "../utils/hooks/splitPanelContext";
import { fetchUserProfile, UserProfile} from "../components/auth/userProfile";
import HomeComponent from "./homeComponent";


const helpPageConfig = {
    '/chatbot-testing': () => import('./chatbot-testing/help'),
    '/dashboard': () => import('./dashboard/help'),
    '/documents': () => import('./documents/help'),
    '/documents/*': () => import('./documents/help'),
    '/prompt-engineering': () => import('./prompt-engineering/help'),
    '/prompt-engineering/*': () => import('./prompt-engineering/help'),
    '/rate-limiting': () => import('./rate-limiting/help'),
    '/rate-limiting/*': () => import('./rate-limiting/help'),
    '/user-admin': () => import('./user-admin/help'),
    '/audit-log': () => import('./audit-log/help'),
    '/': () => import('./help'),
};


type HelpRoutes = keyof typeof helpPageConfig;

export default function Root() {
    return (
        <SplitPanelProvider>
            <FlashProvider>
                <RootContent/>
            </FlashProvider>
        </SplitPanelProvider>
    );

    function RootContent() {
        const {flash} = useFlash();
        const navigate = useNavigate();
        const {setSplitPanelOpen, setSplitPanelContent,} = useSplitPanelControl();
        const location = useLocation();
        const dynamicBreadcrumbs = getBreadcrumbsForPath(location.pathname);
        const [helpContent, setHelpContent] = useState<React.ReactNode | null>(null);
        const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

        useEffect(() => {
            fetchUserProfile().then(profile => {
                setUserProfile(profile);
            });
        }, []);

        // Here we limit the routes the user can go to based on their role profile
        useEffect(() => {
            if (userProfile === null) return;  // Skip if we don't know yet

            if (userProfile['custom:securityProfile'] !== 'Administrator' && !['/', '/chatbot-testing'].includes(location.pathname)) {
                navigate('/');  // or navigate to a "not authorized" page
            }
        }, [userProfile, location.pathname, navigate]);

        useEffect(() => {
            setSplitPanelOpen(false) // close the split panel on navigation
            setSplitPanelContent(null) // Clear the split panel contents on navigation
            const loadHelpPage = async () => {
                // Reduced type casting for better readability
                let fetchHelp;

                for (const path in helpPageConfig) {
                    if (location.pathname.startsWith(path)) {
                        fetchHelp = helpPageConfig[path as HelpRoutes];
                        break;
                    }
                }

                if (fetchHelp) {
                    const HelpComponent = (await fetchHelp()).default;
                    setHelpContent(<HelpComponent/>);
                } else {
                    setHelpContent(null);
                }
            };
            loadHelpPage();
        }, [location.pathname, setSplitPanelContent, setSplitPanelOpen]);


        return (
            <>
                <Shell
                  breadcrumbs={<BreadcrumbGroup items={dynamicBreadcrumbs}/>}
                  navigation={<Navigation />}
                  tools={helpContent}
                  notifications={<Flashbar items={flash} stackItems />}
                >
                    {/* Render HomeComponent only if the current route is '/' */}
                    {location.pathname === '/' && <HomeComponent />}

                    <div id="detail">
                        <Outlet />
                    </div>
                </Shell>
            </>
        );
    }
}