type InputType = 'text' | 'dropdown' | 'number' | 'textarea' | 'toggle';
type EditType = 'READONLY' | 'EDITABLE' | 'HIDDEN';

interface DropdownOption {
  id: string;
  label: string;
}

interface FormFieldConfig {
  name: string;
  label: string;
  inputType: InputType;
  fullWidth?: boolean;
  placeholder?: string;
  editType: EditType;
  constraintText?: string;
  defaultValue?: any;
  options?: DropdownOption[];
  validation?: (value: any) => string | null; // Validation function that returns an error string or null
}

export const formConfig: FormFieldConfig[] = [
  {
    name: 'promptName',
    label: 'Name',
    inputType: 'text',
    editType: 'HIDDEN',
    fullWidth: true,
    constraintText: 'Min 1 char. Alphanumeric and underscores allowed',
    validation: (value) => {
      const regex = /^[a-zA-Z0-9_]{1,}$/;
      if (!regex.test(value)) {
        return 'Name must be unique, at least 1 characters long, and can only contain alphanumeric characters and underscores';
      }
      return null;
    },
  },
  {
    name: 'period',
    label: 'Period',
    inputType: 'dropdown',
    editType: 'EDITABLE',
    constraintText: 'Choose from available options',
    defaultValue: 'daily',
    options: [
      { id: 'hourly', label: 'hourly' },
      { id: 'daily', label: 'daily' },
      { id: 'monthly', label: 'monthly' },
    ],
  },
  {
    name: 'rateLimit',
    label: 'Rate Limit (per user / period)',
    inputType: 'number',
    editType: 'EDITABLE',
    constraintText: 'Choose a message limit between 1 and 1000000',
    defaultValue: 100,
    placeholder: 'Messages rate limit, per user / period',
    validation: (value) => {
      if (!/^\d+$/.test(value)) {
        return 'Value must be a number';
      }
      const numValue = parseInt(value, 10);
      if (numValue < 1 || numValue > 1000000) {
        return 'Value must be between 1 and 1000000';
      }
      return null;
    },
  },
  {
    name: 'status',
    label: 'Active',
    inputType: 'toggle',
    editType: 'EDITABLE',
    defaultValue: true,
  },
];
