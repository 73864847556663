import { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { useFlash } from '../../../utils/hooks/useFlash';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } from './table.config';

import fetcher from '../../../utils/fecther';

import { TableEmptyState, TableNoMatchState } from '../../../components/table/commons';
import { Document, DocumentTableProps } from '../types';

export default function DocumentTable({ isLoading, documents = [], error }: DocumentTableProps) {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Document[]>([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [columnDefinitions, saveWidths] = useColumnWidths(
    'Aiah-Document-TableEditable-Widths',
    EDITABLE_COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useLocalStorage('Aiah-Document-TableEditable-Preferences', DEFAULT_PREFERENCES);
  const { addFlash, removeFlash } = useFlash();
  const navigate = useNavigate();

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
    documents,
    {
      propertyFiltering: {
        filteringProperties,
        empty: <TableEmptyState resourceName="Document" urlPath={'documents'} />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      filtering: {
        empty: <TableEmptyState resourceName="Documents" urlPath={'documents'} />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    setActionLoading(true);
    const details = event.detail;
    if (details.id === 'toggle-active') {
      await fetcher(`/documents/${selectedItems[0].id}`, 'PATCH');
      await mutate('/documents');
    }
    if (details.id === 'remove') {
      try {
        await fetcher(`/documents/${selectedItems[0].id}`, 'DELETE');
        const messageId = uuidv4();
        addFlash({
          type: 'success',
          content: `Successfully deleted document: ${selectedItems[0].id}`,
          dismissible: true,
          dismissLabel: 'Dismiss',
          onDismiss: () => removeFlash(messageId),
          id: messageId,
        });
        await mutate('/documents');
      } catch (error) {
        const messageId = uuidv4();
        addFlash({
          type: 'error',
          content: `Error deleting document: ${selectedItems[0].id}`,
          dismissible: true,
          dismissLabel: 'Dismiss',
          onDismiss: () => removeFlash(messageId),
          id: messageId,
        });
      }
    }
    setActionLoading(false);
  };

  const onRefresh = async () => {
    setRefreshLoading(true);
    await mutate('/documents');
    setRefreshLoading(false);
  };

  const refreshButtonProps = { onClick: onRefresh };

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        onSelectionChange={({ detail: { selectedItems } }: { detail: { selectedItems: Document[] } }) => {
          if (selectedItems && selectedItems.length > 0) {
            const selectedItem: Document = selectedItems[0];
            setSelectedItems((prevItems) => [selectedItem]);
          }
        }}
        selectedItems={selectedItems as any}
        columnDefinitions={columnDefinitions}
        items={items}
        // submitEdit={handleSubmit}
        trackBy="id"
        loadingText="Loading documents"
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
          // stickyColumns={preferences.stickyColumns} // Do not use, causes react resizer error. Leaving in comment to avoid accidental issues.
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        selectionType={'single'}
        loading={isLoading || !documents || refreshLoading}
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button iconName="refresh" ariaLabel="Refresh" {...refreshButtonProps} />
                <ButtonDropdown
                  disabled={!selectedItems || selectedItems.length === 0}
                  onItemClick={handleUtilityItemClick}
                  loading={actionLoading}
                  loadingText={'loading'}
                  items={[
                    {
                      text: 'Toggle active',
                      id: 'toggle-active',
                      disabled: false,
                    },
                    {
                      text: 'Remove',
                      id: 'remove',
                      disabled: false,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
                <Button variant="primary" onClick={() => navigate('/documents/create')}>
                  Create document
                </Button>
              </SpaceBetween>
            }
          >
            Documents {documents && !isLoading ? `(${documents.length})` : <Spinner />}
          </Header>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
            filteringAriaLabel={'Filter documents'}
            filteringPlaceholder={'Filter documents'}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
      />
    </>
  );
}
