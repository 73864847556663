type InputType = 'text' | 'dropdown' | 'number' | 'textarea' | 'toggle';
type EditType = 'READONLY' | 'EDITABLE' | 'HIDDEN';

interface DropdownOption {
  id: string;
  label: string;
}

interface FormFieldConfig {
  name: string;
  label: string;
  inputType: InputType;
  fullWidth?: boolean;
  placeholder?: string;
  editType: EditType;
  constraintText?: string;
  defaultValue?: any;
  options?: DropdownOption[];
  validation?: (value: any) => string | null; // Validation function that returns an error string or null
}

export const formConfig: FormFieldConfig[] = [
  {
    name: 'promptName',
    label: 'Prompt Name',
    inputType: 'text',
    editType: 'HIDDEN',
    fullWidth: true,
    constraintText: 'Min 6 chars. Alphanumeric and underscores allowed',
    validation: (value) => {
      const regex = /^[a-zA-Z0-9_]{6,}$/;
      if (!regex.test(value)) {
        return 'Prompt name must be unique, at least 6 characters long, and can only contain alphanumeric characters and underscores';
      }
      return null;
    },
  },
  {
    name: 'prompt',
    label: 'Prompt',
    inputType: 'textarea',
    editType: 'EDITABLE',
    constraintText: '',
    fullWidth: true,
    validation: (value) => {
      if (value && value.length > 1000) {
        return 'Text exceeds 1000 characters limit';
      }
      return null;
    },
  },
  {
    name: 'genAIModel',
    label: 'Gen AI Model',
    inputType: 'dropdown',
    editType: 'EDITABLE',
    constraintText: 'Choose from available models',
    defaultValue: 'gpt-3.5-turbo-16k',
    options: [
      { id: 'gpt-3.5-turbo-16k', label: 'gpt-3.5-turbo-16k' },
      { id: 'gpt-4', label: 'gpt-4' },
      { id: 'gpt-4o', label: 'gpt-4o' },
      { id: 'gpt-3.5-turbo', label: 'gpt-3.5-turbo' },
      { id: 'gpt-4-1106-preview', label: 'gpt-4-turbo' },
    ],
  },
  {
    name: 'summaryModel',
    label: 'Summarization Model',
    inputType: 'dropdown',
    editType: 'EDITABLE',
    constraintText: 'Choose from available models',
    defaultValue: 'gpt-3.5-turbo',
    options: [
      //   { id: 'text-curie-001', label: 'text-curie-001' },
      { id: 'gpt-3.5-turbo', label: 'gpt-3.5-turbo' },
      { id: 'gpt-3.5-turbo-instruct', label: 'gpt-3.5-turbo-instruct' },
      //   { id: 'davinci-002', label: 'davinci-002' },
    ],
  },
  {
    name: 'embeddingModel',
    label: 'Embeddings Model',
    inputType: 'dropdown',
    editType: 'EDITABLE',
    constraintText: 'Choose from available models',
    defaultValue: 'text-embedding-ada-002',
    options: [
      { id: 'text-embedding-ada-002', label: 'text-embedding-ada-002' },
      // { id: "all-distilroberta-v1", label: "all-distilroberta-v1" }
    ],
  },
  {
    name: 'tokenBudget',
    label: 'Token Limit',
    inputType: 'number',
    editType: 'EDITABLE',
    constraintText: 'Choose a token limit between 300 and 12,000',
    defaultValue: 1200,
    placeholder: 'Enter Gen AI completion token limit',
    validation: (value) => {
      if (!/^\d+$/.test(value)) {
        return 'Value must be a number';
      }
      const numValue = parseInt(value, 10);
      if (numValue < 300 || numValue > 12000) {
        return 'Value must be between 300 and 12,000';
      }
      return null;
    },
  },
  {
    name: 'genAICompletionTokenLimit',
    label: 'Gen AI Completion Token Limit',
    inputType: 'number',
    editType: 'EDITABLE',
    constraintText: 'Choose a token limit between 3256 and 1000',
    defaultValue: 400,
    placeholder: 'Enter Gen AI completion token limit',
    validation: (value) => {
      if (!/^\d+$/.test(value)) {
        return 'Value must be a number';
      }
      const numValue = parseInt(value, 10);
      if (numValue < 256 || numValue > 1000) {
        return 'Value must be between 256 and 1000';
      }
      return null;
    },
  },
  {
    name: 'dynamicPromptCompletionTokenLimit',
    label: 'Dynamic Prompt Completion Token Limit',
    inputType: 'number',
    defaultValue: 400,
    editType: 'EDITABLE',
    constraintText: 'Choose a token limit between 3256 and 1000',
    placeholder: 'Enter dynamic prompt completion token limit',
    validation: (value) => {
      if (!/^\d+$/.test(value)) {
        return 'Value must be a number';
      }
      const numValue = parseInt(value, 10);
      if (numValue < 256 || numValue > 1000) {
        return 'Value must be between 256 and 1000';
      }
      return null;
    },
  },
  {
    name: 'summaryCompletionTokenLimit',
    label: 'Summary Completion Token Limit',
    inputType: 'number',
    defaultValue: 400,
    editType: 'EDITABLE',
    constraintText: 'Choose a token limit between 3256 and 1000',
    placeholder: 'Enter summary completion token limit',
    validation: (value) => {
      if (!/^\d+$/.test(value)) {
        return 'Value must be a number';
      }
      const numValue = parseInt(value, 10);
      if (numValue < 256 || numValue > 1000) {
        return 'Value must be between 256 and 1000';
      }
      return null;
    },
  },
  {
    name: 'documentLimit',
    label: 'Document limit',
    inputType: 'number',
    defaultValue: 20,
    editType: 'EDITABLE',
    constraintText: 'Choose a token limit between 1 and 1000',
    placeholder: 'Enter summary completion token limit',
    validation: (value) => {
      if (!/^\d+$/.test(value)) {
        return 'Value must be a number';
      }
      const numValue = parseInt(value, 10);
      if (numValue < 1 || numValue > 1000) {
        return 'Value must be between 1 and 1000';
      }
      return null;
    },
  },
  {
    name: 'similarityThreshold',
    label: 'Similarity Threshold',
    inputType: 'number',
    editType: 'EDITABLE',
    defaultValue: 0.7,
    constraintText: 'Choose a threshold between 0.1 and 1.0',
    placeholder: 'Segment similarity threshold',
    validation: (value) => {
      if (!/^\d+(\.\d)?$/.test(value)) {
        return 'Value must be a number with up to one decimal place';
      }
      const numValue = parseFloat(value);
      if (numValue < 0.1 || numValue > 1.0) {
        return 'Value must be between 0.1 and 1.0';
      }
      return null;
    },
  },
  {
    name: 'embeddingSearchStatus',
    label: 'Embedding Search enabled',
    inputType: 'toggle',
    editType: 'EDITABLE',
    defaultValue: true,
  },
  {
    name: 'summaryStatus',
    label: 'Summary enabled',
    inputType: 'toggle',
    editType: 'EDITABLE',
    defaultValue: true,
  },
  {
    name: 'dynamicPromptStatus',
    label: 'Search query enabled',
    inputType: 'toggle',
    editType: 'EDITABLE',
    defaultValue: true,
  },
];
