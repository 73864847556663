import React, {useState} from "react";
import Header from "@cloudscape-design/components/header";
import {Box, Pagination, Table} from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import {getTextFilterCounterText} from "../../../../../utils/text-filter";
import {useCollection} from '@cloudscape-design/collection-hooks';
import {FILTERING_PROPERTIES as filteringProperties} from "./filtering_poperties"
import {
    DEFAULT_PREFERENCES,
    EDITABLE_COLUMN_DEFINITIONS,
    Preferences,
} from './table.config'

import {
    TableNoMatchState
} from "../../../../../components/table/commons";
import { useColumnWidths } from "../../../../../components/table/use-column-width";
import { useLocalStorage } from "../../../../../components/use-local-storage";


import useSWR from "swr";
import fetcher from "../../../../../utils/fecther";
import SpaceBetween from "@cloudscape-design/components/space-between";

export default function MinDocsSplitTable(data: any) {
    const [columnDefinitions, saveWidths] = useColumnWidths('Aiah-MinDocsTableSplitPanel-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS)
    const [preferences, setPreferences] = useLocalStorage('Aiah-MinDocsTableSplitPanel-TableEditable-Preferences', DEFAULT_PREFERENCES);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const shouldFetch = selectedItems && selectedItems.length > 0;
    const fetchURL = shouldFetch ? `/queries?templateType=getSegmentedDocument&segmentedContentId=${selectedItems[0].segmentedContentId}` : null;


    const { data: response, error } = useSWR(fetchURL, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });


  const {items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps} = useCollection(
      data.data,
        {
            propertyFiltering: {
                filteringProperties,
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
            },
            filtering: {
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
            },
            pagination: {pageSize: preferences.pageSize},
            sorting: {},
            selection: {},
        }
    );

    return (
            <Table
                {...collectionProps}
                stickyHeader={true}
                resizableColumns={false}
                columnDefinitions={columnDefinitions}
                items={items}
                onColumnWidthsChange={saveWidths}
                selectedItems={selectedItems}
                selectionType={"single"}
                onSelectionChange={({detail: {selectedItems}}) => {
                  if (selectedItems && selectedItems.length > 0) {
                    const selectedItem = selectedItems[0];
                    setSelectedItems(prevItems => [selectedItem]);
                  }
                }}
                trackBy="segmentedContentId"
                loadingText="Loading..."
                columnDisplay={preferences.contentDisplay}
                wrapLines={preferences.wrapLines}
                stripedRows={preferences.stripedRows}
                // stickyColumns={preferences.stickyColumns} // Do not use, causes react resizer error. Leaving in comment to avoid accidental issues.
                contentDensity={preferences.contentDensity as "compact" | "comfortable"}
                header={
                    <Header
                        variant="awsui-h1-sticky"
                    >
                        Segments used ({data?.data?.length})
                    </Header>
                }
                filter={
                    <PropertyFilter
                        {...propertyFilterProps}
                        countText={getTextFilterCounterText(filteredItemsCount)}
                        expandToViewport={true}
                        filteringAriaLabel={"Filter"}
                        filteringPlaceholder={"Filter"}
                    />
                }
                footer={
                    <SpaceBetween size={"xl"}>
                    <Header
                        variant="awsui-h1-sticky"
                        description="Choose a segment to view its contents"
                    >
                        Segment Contents
                    </Header>
                        <Box variant="p">
                            {response?.data[0]?.content}
                        </Box>
                        </SpaceBetween>
                }
                pagination={<Pagination {...paginationProps} />}
                preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
            />
    );
}