import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideNavigation from '@cloudscape-design/components/side-navigation';
import { items, NavigationItem } from './navigationMapping'; // Make sure to import the type NavigationItem
import { fetchUserProfile, UserProfile } from "../auth/userProfile";


// This is used to control the view of menu items between Administrator and everyone else ('user')
const filterNavigationItems = (userProfile: UserProfile, items: NavigationItem[]): NavigationItem[] => {
  if (userProfile['custom:securityProfile'] === 'Administrator') {
    return items
  }

  return items.filter(item => {
    if (item.type === 'section-group') {
      if (item.title === 'Aiah Testing') {
        return true;
      }
    }

    return false;
  });
};

export default function Navigation() {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchUserProfile().then(profile => {
      setUserProfile(profile);
    });
  }, []);

  const [activeHref, setActiveHref] = useState(location.pathname);

  const handleClick = (event: any) => {
    event.preventDefault();
    navigate(event.detail.href);
  }

  useEffect(() => {
    setActiveHref(location.pathname);
  }, [location.pathname]);

  const filteredItems = userProfile ? filterNavigationItems(userProfile, items) : [];

  return (
    <>
      <SideNavigation
        activeHref={activeHref}
        onFollow={handleClick}
        header={{ href: '/', text: 'Chatbot' }}
        items={filteredItems}
      />
    </>
  );
}
