import React from 'react';
import useSWR from 'swr';
import fetcher from "../../utils/fecther";
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {ContentLayout} from '@cloudscape-design/components';
import RateLimitTable from "./components/table";

export default function RateLimiting() {
    const {data, isLoading, error} = useSWR('/rate-limit', fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    description="Manage rate limits by userType"
                >
                    Rate limits
                </Header>
            }
        >
            <SpaceBetween size="m">
                <RateLimitTable isLoading={isLoading} error={error} rates={data?.data}/>
            </SpaceBetween>
        </ContentLayout>
    );
}

