import * as React from "react";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import PromptConfigSelect from "./components/promptConfigSelect";
import PromptIntro from "./components/promptIntro";

type SettingsModalProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
};

export default function SettingsModal({visible, setVisible}: SettingsModalProps) {
    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setVisible(false)}>Close</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Chatbot settings"
        >
            <SpaceBetween direction="vertical" size="l">
                <PromptConfigSelect/>
                <PromptIntro/>
            </SpaceBetween>
        </Modal>
    );
}