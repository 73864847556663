import * as React from "react";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import TextContent from "@cloudscape-design/components/text-content";
import Button from "@cloudscape-design/components/button";
import {RemoveUserProps} from "../types";
import {mutate} from "swr";
import fetcher from "../../../utils/fecther";
import {useState} from "react";
import {useFlash} from "../../../utils/hooks/useFlash";
import {v4 as uuidv4} from "uuid";

export default function RemoveUser({visibleRemoveUser, setVisibleRemoveUser, selectedItems}: RemoveUserProps) {
    const [loading, setLoading] = useState(false)
    const {addFlash, removeFlash} = useFlash();

    async function handleDelete() {
        if (selectedItems && selectedItems.length > 0) {
            setLoading(true);
            const username = selectedItems[0].Username;
            try {
                await fetcher(`/users/${username}`, 'DELETE');
                const messageId = uuidv4();
                addFlash({
                    type: "success",
                    content: `Successfully deleted user: ${username}`,
                    dismissible: true,
                    dismissLabel: "Dismiss",
                    onDismiss: () => removeFlash(messageId),
                    id: messageId
                });
                await mutate('/users');
            } catch (error) {
                const messageId = uuidv4();
                addFlash({
                    type: "error",
                    content: `Error deleting user: ${error}`,
                    dismissible: true,
                    dismissLabel: "Dismiss",
                    onDismiss: () => removeFlash(messageId),
                    id: messageId
                });
            } finally {
                setVisibleRemoveUser(false);
                setLoading(false);
            }
        }
    }

    return (
        <Modal
            onDismiss={() => setVisibleRemoveUser(false)}
            visible={visibleRemoveUser}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setVisibleRemoveUser(false)}>Cancel</Button>
                        <Button variant="primary" loading={loading} onClick={() => handleDelete()}>Remove user</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Remove selected user"
        >
            <SpaceBetween
                direction="vertical"
                size="xs"
            >
                <TextContent>
                    <p>This action cannot be undone, however the user can be added again instantly. Removing the user
                        will prevent them from accessing any information immediately </p>
                    <h4>
                        {selectedItems && selectedItems.length > 0
                            ? selectedItems[0].Username
                            : "-"}
                    </h4>
                </TextContent>
            </SpaceBetween>
        </Modal>
    );
}
