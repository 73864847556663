import React from 'react';
import Container from '@cloudscape-design/components/container';
import Header from '@cloudscape-design/components/header';
import FileUpload from "@cloudscape-design/components/file-upload";
import FormField from "@cloudscape-design/components/form-field";

export default function DocumentUpload({value, setValue}: any) {

    return (
        <Container header={<Header variant="h2">Document upload</Header>}>
            <FormField
                label="Select new document to be added to the the chatbot"
            >
                <FileUpload
                    onChange={({detail}) => setValue(detail.value)}
                    value={value}
                    i18nStrings={{
                        uploadButtonText: e =>
                            e ? "Choose files" : "Choose file",
                        dropzoneText: e =>
                            e
                                ? "Drop files to upload"
                                : "Drop file to upload",
                        removeFileAriaLabel: e =>
                            `Remove file ${e + 1}`,
                        limitShowFewer: "Show fewer files",
                        limitShowMore: "Show more files",
                        errorIconAriaLabel: "Error"
                    }}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    tokenLimit={3}
                    accept={"txt"}
                    constraintText="Select or drag and drop"
                />
            </FormField>
        </Container>
    );
}
