import React from 'react';
import ContentLayout from '@cloudscape-design/components/content-layout';
import Grid from '@cloudscape-design/components/grid';
import '@cloudscape-design/global-styles/dark-mode-utils.css';
import { PeriodProvider, usePeriod} from "./periodContext";

import {
  BaseStaticWidget,
  getConversations,
  getMessages,
  getReverts,
  getMissedMessages,
  getUserFeedback,
  getUserFeedbackComments
} from './widgets';
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import CxDateRangePicker from "../../components/dataRangePicker";
import { Select } from "@cloudscape-design/components";

function Content() {
  return (
    <Grid
      gridDefinition={[
        { colspan: { l: 6, m: 6, default: 12 } },
        { colspan: { l: 6, m: 6, default: 12 } },
        { colspan: { l: 6, m: 6, default: 12 } },
        { colspan: { l: 6, m: 6, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
      ]}
    >
      {[
        getMessages,
        getConversations,
        getReverts,
        getUserFeedback,
        getUserFeedbackComments,
        getMissedMessages,
      ].map((widget, index) => (
        <BaseStaticWidget key={index} config={widget.data} />
      ))}
    </Grid>
  );
}

export default function Dashboard() {
  return (
    <PeriodProvider>
      <DashboardContent />
    </PeriodProvider>
  );
}

function DashboardContent() {
  const { setPeriod, period, audience, setAudience } = usePeriod();

  function handleDateChange(newDate: any) {
    setPeriod(newDate);
  }

  function handleAudienceChange(value: { label: string; value: string }) {
    setAudience(value );
  }

  return (
          <ContentLayout
            header={
              <>
                <Header
                  actions={
                    <SpaceBetween size="xs" direction="horizontal">
                      <CxDateRangePicker
                        setDateRangeValue={handleDateChange}
                        defaults={period}
                        limitDuration={true}
                        rangeSelectorMode={"default"}
                      />
                      <Select
                        selectedOption={audience}
                        onChange={({ detail }) =>
                          handleAudienceChange(detail.selectedOption as { label: string; value: string; })
                        }
                        options={[
                          { label: "All", value: "All" },
                          { label: "Admin", value: "Admin" },
                          { label: "Subscribers", value: "Subscribers" },
                          { label: "Freemium", value: "Freemium" },
                          { label: "Corporate Client 1", value: "Corp1" }
                        ]}
                      />

                    </SpaceBetween>

                  }
                >Aiah Dashboard</Header>
              </>
            }>
            <Content />
          </ContentLayout>
  );
}

