import React from 'react';
import {
  Header,
} from "@cloudscape-design/components";
import { WidgetConfig } from '../interfaces';
import GetWidgetData from "../../../../utils/getWidgetData";
import { usePeriod } from "../../periodContext";
import UserCommentsTable from "./table";


export const getUserFeedbackComments: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'mixedContent',
    title: 'Get User Feedback Comments',
    description: 'Displays the feedback provided by the user',
    header: GetUserFeedbackCommentsHeader,
    content: GetUserFeedbackComments,
    staticMinHeight: 200,
  },
};

function GetUserFeedbackCommentsHeader() {
  return (
    <Header variant="h2" description="Vebatim feedback provided by the user. Users can provide feedback per message. Score is mandatory, comments are optional">
      User Feedback
    </Header>
  );
}

export default function GetUserFeedbackComments() {

  const { period,audience } = usePeriod();

  const widgetParams = ''
  // @ts-ignore
  const { data, isLoading, error } = GetWidgetData({ templateType: "getUserFeedbackComments", period, widgetParams, audience});


  return (
      <UserCommentsTable userFeedback={data?.data} error={error} isLoading={isLoading}/>
  );
}
