import React from 'react';
import { CollectionPreferences } from "@cloudscape-design/components";


const rawColumns = [
    {
        id: "chatMessageId",
        header: "Message id",
        cell: item => item.chatMessageId || "-",
        sortingField: "chatMessageId",
        width: 150
    },
    {
        id: "numDocsUsed",
        header: "No, of Docs",
        cell: item => item.numDocsUsed || 0,
        sortingField: "numDocsUsed",
        width: 150
    },
    {
        id: "averageTokenCount",
        header: "Avg token usage",
        cell: item => item.averageTokenCount ? parseFloat(item.averageTokenCount).toFixed(2) : "-",
        sortingField: "averageTokenCount",
        width: 100
    },
    {
        id: "totalTokenCount",
        header: "Total token usage",
        cell: item => item.totalTokenCount || "-",
        sortingField: "totalTokenCount",
        width: 100
    },
    {
        id: "question",
        header: "Question",
        cell: item => item.question || "-",
        width: 200
    },
    {
        id: "answer",
        header: "Answer",
        cell: item => item.userType || "-",
        sortingField: "answer",
        width: 200
    },
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));


const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});

const CONTENT_DISPLAY_OPTIONS = [
    {id: 'chatMessageId', label: 'Message id', alwaysVisible: true},
    {id: 'numDocsUsed', label: 'No. Docs', alwaysVisible: false},
    {id: 'averageTokenCount', label: 'Avg token usage', alwaysVisible: false},
    {id: 'totalTokenCount', label: 'Total token usage', alwaysVisible: false},
    {id: 'question', label: 'Question', alwaysVisible: false},
    {id: 'answer', label: 'Answer', alwaysVisible: false},
];

export const PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10 items'},
    {value: 30, label: '30 items'},
    {value: 50, label: '50 items'},
];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
        {id: 'chatMessageId', visible: true},
        {id: 'numDocsUsed', visible: true},
        {id: 'averageTokenCount', visible: true},
        {id: 'totalTokenCount', visible: true},
        {id: 'question', visible: true},
        {id: 'answer', visible: true},
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: "comfortable",
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled = false,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
                            }) => (
    <CollectionPreferences
        title={"Preferences"}
        cancelLabel={"Cancel"}
        confirmLabel={"Confirm"}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({detail}) => setPreferences(detail)}
        pageSizePreference={{title: "Page size", options: pageSizeOptions}}
        wrapLinesPreference={{label: "Wrap lines", description: "Select to see all the text and wrap the lines"}}
        stripedRowsPreference={{label: "Striped rows", description: "Select to add alternating shaded rows"}}
        contentDensityPreference={{
            label: "Compact mode",
            description: "Select to display content in a denser, more compact mode"
        }}
        contentDisplayPreference={{title: "Select visible content / order", options: contentDisplayOptions}}
    />
);