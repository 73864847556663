export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'segmentedContentId',
        key: 'segmentedContentId',
        groupValuesLabel: 'segmentedContentId values',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'cosineSimilarity',
        key: 'cosineSimilarity',
        groupValuesLabel: 'cosineSimilarity',
        operators: [':', '!:', '>', '<'] as const,
    },
    {
        propertyLabel: 'originalFilename',
        key: 'originalFilename',
        groupValuesLabel: 'originalFilename',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'segmentFilename',
        key: 'segmentFilename',
        groupValuesLabel: 'segmentFilename',
        operators: [':', '!:', '=', '!='] as const,
    },
] as const;
