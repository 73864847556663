import React, {ReactNode} from 'react';
import useSWR from 'swr';
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Icon,
    SpaceBetween,
    SplitPanel,
    Spinner,
} from '@cloudscape-design/components';
import fetcher from "../../../utils/fecther";

interface AuditSplitPanelDetailsProps {
    selectedItems: any;
}

interface ValueWithLabelProps {
    label: string;
    children: ReactNode;
}

const ValueWithLabel: React.FC<ValueWithLabelProps> = ({label, children}) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);

const AuditSplitPanelDetails: React.FC<AuditSplitPanelDetailsProps> = ({selectedItems}) => {
    const {data, error, isLoading} = useSWR(`/audit/${selectedItems.detail[0].dateTime}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });

    let event = selectedItems?.detail[0]
    let Payload = data?.data[0]?.data

    return (
        <SplitPanel header="Audit Log" closeBehavior="collapse">
            <Container header={<Header headingTagOverride="h3">Event</Header>}>
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Datetime">{event.dateTime}</ValueWithLabel>
                        <ValueWithLabel label="Requester">
                            {event.requester}
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Resource Type">
                            {event.type}
                        </ValueWithLabel>
                        <ValueWithLabel label="Resource Name">
                            {event.resource}
                        </ValueWithLabel>
                        <ValueWithLabel label="Action Performed">
                            {event.action}
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
                <ColumnLayout columns={1} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Payload">
                            {isLoading ? <Spinner/> : JSON.stringify(Payload)}
                            {error && <><Icon
                                name="status-negative"
                                variant="error"
                            /> Error retrieving data</>}
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
        </SplitPanel>
    );
}

export default AuditSplitPanelDetails;


