import * as React from "react";
import {Select, FormField} from "@cloudscape-design/components";
import fetcher from "../../../utils/fecther";
import {useContext, useEffect, useState} from "react";
import {ChatWebSocketContext, ChatWebSocketContextType} from "./contexts/chatWebSocketContext";
import map from 'lodash/map';

type OptionType = {
    label: string | undefined;
    value: string;
};

export default function PromptIntro() {
    const {promptConfig, setPromptConfig} =
        useContext<ChatWebSocketContextType>(ChatWebSocketContext);
    const [promptOptions, setPromptOptions] = useState<OptionType[]>([]);

    useEffect(() => {
        if (promptConfig !== undefined) {
            const fetchPromptConfig = async () => {
                try {
                    const response = await fetcher('/prompt-dictionary');

                    const transformedOptions: OptionType[] = map(response.data, (item): OptionType | null => {
                        if (!item.version) {
                            return null;
                        }
                        return {
                            label: item.version,
                            value: item.version
                        };
                    }).filter((option): option is OptionType => option !== null);

                    setPromptOptions(transformedOptions);
                } catch (error) {
                }
            }
            fetchPromptConfig();
            setSelectedOption({label: promptConfig, value: promptConfig});
        }
    }, [promptConfig]);

    function handlePromptOptionsChange(option: OptionType) {
        setSelectedOption(option);
        setPromptConfig(option.value);
    }

    const [selectedOption, setSelectedOption] = useState<OptionType>({label: '', value: ''});

    return (
        <FormField
            description="Use the selector below to use the prompt-engineering configuration required"
            label="Prompt configuration"
        >
            <Select
                selectedOption={selectedOption}
                onChange={({detail}) => handlePromptOptionsChange(detail.selectedOption as OptionType)}
                options={promptOptions}
            />
        </FormField>
    );
}
