import * as React from 'react';
import ContentLayout from '@cloudscape-design/components/content-layout';
import Container from '@cloudscape-design/components/container';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';

export default function HomeComponent() {
  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1" description="Aiah managment UI">
            Welcome
          </Header>
        </SpaceBetween>
      }
    >
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">Introduction</Header>}>
          This application is used by the Parent Sense administrators and testers for the purpose of managing and
          testing the Aiah chatbot. Please choose from the menu item(s) on the left to begin.
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
}
