import React, {useState, useEffect} from 'react';
import fetcher from "../../../utils/fecther";
import {
    ColumnLayout,
    Container,
    Header,
    ContentLayout,
    SpaceBetween,
    Form,
    Input,
    Button,
    FormField
} from '@cloudscape-design/components';
import {useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from "uuid";
import {useFlash} from "../../../utils/hooks/useFlash";
import Select, {SelectProps} from "@cloudscape-design/components/select";

export default function UserAdminCreate() {
    // state
    const [email, setEmail] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [givenNameErrorText, setGivenNameErrorText] = useState("");
    const [familyNameErrorText, setFamilyNameErrorText] = useState("");
    const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>({
        label: "Administrator",
        value: "admin"
    });
    const [canSubmit, setCanSubmit] = useState(false);

    const [formSubmited, setFormSubmited] = useState<boolean>(false);

    // Hooks
    const navigate = useNavigate();
    const {addFlash, removeFlash} = useFlash();

    function isValidEmail(email: any) {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
        return regex.test(email);
    }

    function isValidName(name: string): boolean {
        const regex = /^[a-zA-Z ]{2,}$/; // Ensure at least 2 characters
        return regex.test(name);
    }

    function handleEmailChange(value: string) {
        setEmail(value);
        if (!isValidEmail(value)) {
            setEmailErrorText("Please enter a valid email address.");
        } else {
            setEmailErrorText("");
        }
    }

    useEffect(() => {
        const isFormValid = isValidEmail(email) && isValidName(givenName) && isValidName(familyName);
        setCanSubmit(isFormValid);
    }, [email, givenName, familyName]);


    // Handle form submission
    async function handleSubmitForm() {
        setFormSubmited(true);

        try {
            let payload = {
                "email": email,
                "firstName": givenName,
                "lastName": familyName,
                "securityProfile": selectedOption?.label
            }

            await fetcher(`/users`, 'POST', payload);
            const messageId = uuidv4();
            addFlash({
                type: "success",
                content: `Successfully created user ${email}`,
                dismissible: true,
                dismissLabel: "Dismiss",
                onDismiss: () => removeFlash(messageId),
                id: messageId
            });
            navigate('/user-admin')
        } catch (error) {
            const messageId = uuidv4();
            addFlash({
                type: "error",
                content: `Error creating user ${email}`,
                dismissible: true,
                dismissLabel: "Dismiss",
                onDismiss: () => removeFlash(messageId),
                id: messageId
            });
        } finally {
            setFormSubmited(false);
        }
    };

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                >

                </Header>
            }
        >
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Use this form to create a new user. These users are onboarded to this application only."
                    >
                        Create admin user
                    </Header>
                }>
                <form onSubmit={e => {
                    e.preventDefault();
                    handleSubmitForm();
                }}>
                    <Form
                        variant="embedded"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link"
                                        onClick={() => navigate('/user-admin')}>
                                    Cancel
                                </Button>
                                <Button variant="primary"
                                        loading={formSubmited}
                                        disabled={!canSubmit}>Submit</Button>
                            </SpaceBetween>
                        }
                    >
                        <ColumnLayout columns={2} variant="text-grid">
                            <FormField
                                label="Provide users email"
                                description="Provide the email exactly as created in the identity store"
                                errorText={emailErrorText}
                            >
                                <Input inputMode="email" onChange={({detail}) => handleEmailChange(detail.value)}
                                       value={email}/>

                            </FormField>
                            <FormField
                                label="Select permission type"
                                description="Please see the help pages for details on permission sets"
                            >
                                <Select
                                    selectedOption={selectedOption}
                                    onChange={({detail}) =>
                                        setSelectedOption(detail.selectedOption)
                                    }
                                    options={[
                                        {label: "Administrator", value: "admin"},
                                        {label: "User", value: "User"},
                                    ]}
                                />
                            </FormField>
                            <FormField
                                label="First name"
                                errorText={givenNameErrorText}
                            >
                                <Input onChange={({detail}) => {
                                    setGivenName(detail.value);
                                    if (!isValidName(detail.value)) {
                                        setGivenNameErrorText("Please enter a valid first name.");
                                    } else {
                                        setGivenNameErrorText("");
                                    }
                                }}
                                       value={givenName}/>
                            </FormField>
                            <FormField
                                label="Last name"
                                errorText={familyNameErrorText}
                            >
                                <Input onChange={({detail}) => {
                                    setFamilyName(detail.value);
                                    if (!isValidName(detail.value)) {
                                        setFamilyNameErrorText("Please enter a valid last name.");
                                    } else {
                                        setFamilyNameErrorText("");
                                    }
                                }}
                                       value={familyName}/>
                            </FormField>
                        </ColumnLayout>
                    </Form>
                </form>
            </Container>
        </ContentLayout>
    );
}

