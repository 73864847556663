import React, {useState} from 'react';
import {SplitPanel} from '@cloudscape-design/components';

interface SplitPanelProps {
    defaultOpen?: boolean;
    defaultSize?: number;
    defaultContent?: any;
}

export interface SplitPanelPreferenceDetails {
    detail: {
        position: "side" | "bottom";
    };
}

export interface SplitPanelResizeEvent {
    detail: {
        size: number;
    };
}

export interface SplitPanelToggleEvent {
    detail: {
        open: boolean;
    };
}

export const useSplitPanel = ({defaultOpen = false, defaultSize = 350, defaultContent = null}: SplitPanelProps) => {
    const [splitPanelContent, setSplitPanelContent] = useState(defaultContent);
    const [splitPanelSize, setSplitPanelSize] = useState(defaultSize);
    const [splitPanelOpen, setSplitPanelOpen] = useState(defaultOpen);
    const [splitPanelPreferences, setSplitPanelPreferences] = useState({
        position: "bottom" as "bottom" | "side"
    });

    const onSplitPanelResize = ({detail: {size}}: SplitPanelResizeEvent) => {
        setSplitPanelSize(size);
    };


    const onSplitPanelToggle = ({detail: {open}}: SplitPanelToggleEvent) => {
        setSplitPanelOpen(open);
    };

    const onSplitPanelPreferences = ({detail: {position}}: SplitPanelPreferenceDetails) => {
        if (splitPanelPreferences.position === "side") {
            setSplitPanelPreferences({position: "bottom"});
        } else {
            setSplitPanelPreferences({position: "side"});
        }
    }

    return {
        setSplitPanelPreferences,
        splitPanelContent,
        setSplitPanelContent,
        setSplitPanelOpen,
        splitPanelSize,
        onSplitPanelResize,
        splitPanelOpen,
        onSplitPanelToggle,
        splitPanelPreferences,
        onSplitPanelPreferences,
    };
}

export const SplitPanelContent = () => (
  <SplitPanel header="My Split Panel Header" closeBehavior="collapse">
      My Split Panel Content
  </SplitPanel>
);
