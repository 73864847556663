import React, {useEffect, useState} from 'react';
import useSWR from 'swr';
import fetcher from "../../utils/fecther";
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {ContentLayout} from '@cloudscape-design/components';
import UserTable from "./components/table";
import {UserProfile, fetchUserProfile} from '../../components/auth/userProfile'


export default function UserAdmin() {
    const {data, isLoading, error} = useSWR('/users', fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });
    const [profile, setProfile] = useState<UserProfile | null>(null);


    useEffect(() => {
        async function getUserProfile() {
            const profileData = await fetchUserProfile();
            setProfile(profileData);
        }

        getUserProfile();
    }, []);

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    description="Add / remove emails for the users within the domain to control their access."
                >
                    Manage application users
                </Header>
            }
        >
            <SpaceBetween size="m">
                <UserTable isLoading={isLoading} error={error} users={data?.data} currentUser={profile?.email || ""}/>
            </SpaceBetween>
        </ContentLayout>
    );
}

