import React, {createContext, useContext, ReactNode} from 'react';
import {useSplitPanel} from "../../layouts/shell/splitPanel";

import {
    SplitPanelPreferenceDetails,
    SplitPanelResizeEvent,
    SplitPanelToggleEvent
} from "../../layouts/shell/splitPanel";

interface SplitPanelContextProps {
    children: ReactNode;
}

interface SplitPanelContextValue {
    setSplitPanelOpen: any;
    splitPanelContent: any;
    setSplitPanelPreferences: any,
    setSplitPanelContent: any;
    splitPanelSize: number;
    onSplitPanelResize: (event: SplitPanelResizeEvent) => void;
    splitPanelOpen: boolean;
    onSplitPanelToggle: (event: SplitPanelToggleEvent) => void;
    splitPanelPreferences: {
        position: "side" | "bottom";
    };
    onSplitPanelPreferences: (event: SplitPanelPreferenceDetails) => void;
}

const SplitPanelContext = createContext<SplitPanelContextValue | null>(null);


export const SplitPanelProvider = ({children}: SplitPanelContextProps) => {
    const splitPanelValues = useSplitPanel({defaultOpen: false, defaultSize: 300});

    return (
      <SplitPanelContext.Provider value={splitPanelValues}>
          {children}
      </SplitPanelContext.Provider>
    );
}

export const useSplitPanelControl = () => {
    const context = useContext(SplitPanelContext);
    if (!context) {
        throw new Error('useSplitPanelControl must be used within a SplitPanelProvider');
    }
    return context;
}
