import React, {useState} from "react";
import Header from "@cloudscape-design/components/header";
import {Pagination, SpaceBetween, Spinner, Table} from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from "@cloudscape-design/components/alert";
import {getTextFilterCounterText} from "../../../utils/text-filter";
import {useCollection} from '@cloudscape-design/collection-hooks';
import {useLocalStorage} from "../../../components/use-local-storage";
import {useColumnWidths} from "../../../components/table/use-column-width";
import {useSplitPanelControl} from "../../../utils/hooks/splitPanelContext";
import {FILTERING_PROPERTIES as filteringProperties} from "./filtering_poperties"
import AuditSplitPanelDetails from "./auditSplitPanelDetails";
import CxDateRangePicker from "../../../components/dataRangePicker";

import {
    DEFAULT_PREFERENCES,
    EDITABLE_COLUMN_DEFINITIONS,
    Preferences,
} from './table.config'


import {
    TableNoMatchState,
} from '../../../components/table/commons'

import {AuditLog, AuditTableProps} from "../types";

export default function AuditTable({
                                       isLoading,
                                       auditLogs = [],
                                       error,
                                       onDateRangeChange,
                                       defaultEnd,
                                       defaultStart
                                   }: AuditTableProps) {
    const [selectedItems, setSelectedItems] = useState<AuditLog[]>([]);
    const [columnDefinitions, saveWidths] = useColumnWidths('Aiah-Audit-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS)
    const [preferences, setPreferences] = useLocalStorage('Aiah-Audit-TableEditable-Preferences', DEFAULT_PREFERENCES);

    const {setSplitPanelOpen, setSplitPanelContent,} = useSplitPanelControl();


    const {items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps} = useCollection(
        auditLogs,
        {
            propertyFiltering: {
                filteringProperties,
                // empty: <TableEmptyState resourceName="Audit"/>,
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
            },
            filtering: {
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
            },
            pagination: {pageSize: preferences.pageSize},
            sorting: {},
            selection: {},
        }
    );

    function onSelectionChange(data: { detail: any }) {
        setSplitPanelContent(<AuditSplitPanelDetails selectedItems={data}/>);
        setSplitPanelOpen(true)
    }

    return (
        <>
            <Table
                {...collectionProps}
                stickyHeader={true}
                resizableColumns={true}
                onColumnWidthsChange={saveWidths}
                columnDefinitions={columnDefinitions}
                onSelectionChange={({detail: {selectedItems}}) => {
                    onSelectionChange({detail: selectedItems});
                    if (selectedItems && selectedItems.length > 0) {
                        const selectedItem: AuditLog = selectedItems[0];
                        setSelectedItems(prevItems => [selectedItem]);
                    }
                }}
                selectedItems={selectedItems as any}
                items={items}
                selectionType={"single"}
                loadingText="Loading audit logs"
                columnDisplay={preferences.contentDisplay}
                wrapLines={preferences.wrapLines}
                stripedRows={preferences.stripedRows}
                // stickyColumns={preferences.stickyColumns} // Do not use, causes react resizer error. Leaving in comment to avoid accidental issues.
                contentDensity={preferences.contentDensity as "compact" | "comfortable"}
                loading={isLoading || !auditLogs}
                header={
                    <SpaceBetween size="xs">
                        <Header variant="awsui-h1-sticky">
                            Audit logs {auditLogs && !isLoading ? `(${auditLogs.length})` : <Spinner/>}
                        </Header>
                        <CxDateRangePicker
                            setDateRangeValue={onDateRangeChange}
                            defaults={
                                defaultStart && defaultEnd ?
                                    {
                                        startDate: new Date(defaultStart).toISOString(),
                                        endDate: new Date(defaultEnd).toISOString()
                                    }
                                    : {}
                            }
                            limitDuration={false}
                            rangeSelectorMode={"absolute-only"}
                        />
                    </SpaceBetween>
                }
                filter={
                    <PropertyFilter
                        {...propertyFilterProps}
                        countText={getTextFilterCounterText(filteredItemsCount)}
                        expandToViewport={true}
                        filteringAriaLabel={"Filter results"}
                        filteringPlaceholder={"Filter results"}
                    />
                }
                pagination={<Pagination {...paginationProps} />}
                preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
            />
            {error &&
                <Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header="Error getting data"
                >
                    Refresh the page to try again.
                </Alert>}
        </>
    );
}