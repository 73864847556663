import useSWR from 'swr';
import fetcher from "./fecther";
import {formatDateTimePickerApi} from "./timeUtils";

export default function GetWidgetData({templateType, period, widgetParams, audience} ) {
    const {startDate, endDate} = formatDateTimePickerApi(period);

    const interval = period.type === 'relative' ? 30000 : 0;

    // Create URLSearchParams object
    const params = new URLSearchParams();
    params.append('start', startDate);
    params.append('end', endDate);
    params.append('templateType', templateType);
    params.append('userType', audience.value);

    // Construct the URL with the query string
    const url = `/queries?${params.toString()}${widgetParams}`;
    const {data, isLoading, error} = useSWR(url, fetcher, {
        shouldRetryOnError: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: interval,
    });

    return {data, isLoading, error};
}
