const dayjs = require('dayjs');
require('dayjs/plugin/utc');
require('dayjs/plugin/timezone');

dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));

export function getCurrentDate() {
  return dayjs().format();
}

export function getTodayDate() {
  const today = dayjs().startOf('day').format();
  return encodeURIComponent(today);
}

export function getEpochTimeMinusHours(hours) {
  return Math.floor((Date.now() - hours * 60 * 60 * 1000) / 1000);
}

// This is used by components that need to query the database using start and end times. The component will get CxDateTimePicker that supports
// relative and absolute time ranges but needs to be formatted before making the API request
export function formatDateTimePickerApi(period) {
  if (period?.key === 'today') {
    const start = dayjs().startOf('day').format();
    const end = 'now';
    return { startDate: start, endDate: end };
  }
  if (period?.type === 'relative') {
    const { amount, unit } = period;
    const start = `${amount}${unit}`;
    const end = 'now';
    return { startDate: start, endDate: end };
  }
  return { startDate: period.startDate, endDate: period.endDate };
}
