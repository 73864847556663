import React from 'react';
import {
    CollectionPreferences, Icon
} from "@cloudscape-design/components";


const rawColumns = [
    {
        id: "id",
        header: "Message id",
        cell: item => item.id || "-",
        // sortingField: "id",
        width: 200
    },
    {
        id: "rating",
        header: "Rating",
        cell: item => item.rating === "1" ? <Icon name="thumbs-up-filled" variant="success" /> : <Icon name="thumbs-down-filled" variant="error" />,
        sortingField: "rating",
        width: 100
    },
    {
        id: "comment",
        header: "Feedback",
        cell: item => item.comment || "-",
        width: 300
    },
    {
        id: "question",
        header: "Question",
        cell: item => item.question || "-",
        width: 200
    },
    {
        id: "userType",
        header: "Audience Type",
        cell: item => item.userType || "-",
        sortingField: "userType",
        width: 150
    },
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));


const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});

const CONTENT_DISPLAY_OPTIONS = [
    {id: 'id', label: 'Message id', alwaysVisible: true},
    {id: 'rating', label: 'Rating', alwaysVisible: false},
    {id: 'comment', label: 'Feedback', alwaysVisible: false},
    {id: 'question', label: 'Question', alwaysVisible: false},
    {id: 'userType', label: 'Audience Type', alwaysVisible: false},
];

export const PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10 items'},
    {value: 30, label: '30 items'},
    {value: 50, label: '50 items'},
];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
        {id: 'id', visible: true},
        {id: 'rating', visible: true},
        {id: 'comment', visible: true},
        {id: 'question', visible: true},
        {id: 'userType', visible: true},
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: "comfortable",
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled = false,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
                            }) => (
    <CollectionPreferences
        title={"Preferences"}
        cancelLabel={"Cancel"}
        confirmLabel={"Confirm"}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({detail}) => setPreferences(detail)}
        pageSizePreference={{title: "Page size", options: pageSizeOptions}}
        wrapLinesPreference={{label: "Wrap lines", description: "Select to see all the text and wrap the lines"}}
        stripedRowsPreference={{label: "Striped rows", description: "Select to add alternating shaded rows"}}
        contentDensityPreference={{
            label: "Compact mode",
            description: "Select to display content in a denser, more compact mode"
        }}
        contentDisplayPreference={{title: "Select visible content / order", options: contentDisplayOptions}}
    />
);